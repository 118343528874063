import {
  Order,
  OrderResponse,
  TotalCount,
  UpdateOrderFormValues,
} from "./../type/order";

import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfOrder(): Promise<Order[]> {
  const response: AxiosResponse<OrderResponse> = await axios.get(
    `${API_URL}/orders`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getListOfOrderByOrderStatus(
  status: string
): Promise<Order[]> {
  const response: AxiosResponse<OrderResponse> = await axios.get(
    `${API_URL}/orders?status=${status}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getSingleOrder(id: number) {
  const response = await axios.get(`${API_URL}/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data.output;
}

export async function getCountOfOrder(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/orders/total/count`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
}

export async function updateOrder(id: number, data: UpdateOrderFormValues) {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const response = await axios.put(
      `${API_URL}/orders/update-status/${id}`,
      data,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function deleteOrder(id: number) {
  try {
    const response = await axios.delete(
      `${API_URL}/categories/parent-category/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete parent category");
  }
}

export async function cancelOrder(order_id: number) {
  try {
    return await axios
      .post(
        `${API_URL}/orders/cancel-order/`,
        { order_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
        };
      })
      .catch((err) => {
        return {
          status: err.response.status,
          message: err.response.data.message,
        };
      });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete parent category");
  }
}
