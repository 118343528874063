import React, { useEffect, useState } from "react";
import Layout from "../Fragments/Layout";
import Header from "../Fragments/Header";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { UpdateAdminFormValues } from "../type/admin";
import * as Yup from "yup";

const adminSchema = Yup.object().shape({
  admin_first_name: Yup.string().required("First name is required"),
  admin_middle_name: Yup.string(),
  admin_last_name: Yup.string().required("Last name is required"),
  admin_email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  admin_phone: Yup.string().required("Phone number is required"),
  admin_address: Yup.string(),
  admin_city: Yup.string(),
  admin_state: Yup.string(),
  admin_zip: Yup.string(),
  admin_country: Yup.string(),
  admin_username: Yup.string().required("Username is required"),
});

function UpdateProfile() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        const formData = new FormData();
        formData.append("image", selectedImage);
        await axios
          .put(`${process.env.REACT_APP_API_URL}/admin/pic`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            setSuccessMessage("Image uploaded successfully.");
            setSelectedImage(null);
          })
          .catch((error) => {});
      } catch (error) {
        setErrorMessage("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setErrorMessage("Please select an image to upload.");
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [initialValues, setInitialValues] = useState<UpdateAdminFormValues>({
    admin_first_name: "",
    admin_middle_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_address: "",
    admin_city: "",
    admin_state: "",
    admin_zip: "",
    admin_country: "",
    admin_username: "",
    admin_photo: "",
  });

  async function init() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInitialValues({
          admin_first_name: response.data.output.admin_first_name,
          admin_middle_name: response.data.output.admin_middle_name,
          admin_last_name: response.data.output.admin_last_name,
          admin_email: response.data.output.admin_email,
          admin_phone: response.data.output.admin_phone,
          admin_address: response.data.output.admin_address,
          admin_city: response.data.output.admin_city,
          admin_state: response.data.output.admin_state,
          admin_zip: response.data.output.admin_zip,
          admin_country: response.data.output.admin_country,
          admin_username: response.data.output.admin_username,
          admin_photo: response.data.output.admin_photo,
        });
        setImageUrl(response.data.output.admin_photo);
      })
      .catch((error) => {});
  }

  const handleSubmit = async (values: any) => {
    return await axios
      .patch(`${process.env.REACT_APP_API_URL}/admin`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage("Profile updated successfully");
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-8">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-center text-center text-md-start mb-3">
                      <figure className="me-4 flex-shrink-0">
                        {imagePreview ? (
                          <img
                            width="100"
                            className="rounded-pill"
                            src={imagePreview}
                            alt="Preview"
                          />
                        ) : (
                          <img
                            width="100"
                            className="rounded-pill"
                            src={imageUrl.length > 0 ? imageUrl : "/assets/images/default.png"}
                            alt={initialValues.admin_photo}
                          />
                        )}
                      </figure>
                      <div className="flex-fill">
                        <h5 className="mb-3">
                          {`${initialValues?.admin_first_name}  ${initialValues?.admin_last_name}`}
                        </h5>
                      </div>
                    </div>
                    {successMessage && (
                      <div className="alert alert-success">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger">{errorMessage}</div>
                    )}
                    <div className="row">
                      <form onSubmit={handleImageSubmit}>
                        <div className="row g-4 mb-3">
                          <div className="col-md-12">
                            <div className="my-2">
                              <input
                                type="file"
                                name="image"
                                accept="image/*"
                                className="form-control"
                                onChange={(event) => {
                                  handleImageChange(event);
                                  handleimageSet(event);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center my-3">
                          <button type="submit" className="btn btn-primary">
                            Update Image
                          </button>
                        </div>
                      </form>
                    </div>

                    {initialValues.admin_email.length > 0 ? (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={adminSchema}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <div className="card mb-4">
                            <div className="card-body">
                              <h6 className="card-title mb-4">
                                User Information
                              </h6>

                              <div className="row mt-5">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      First Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_first_name"
                                    />
                                    <ErrorMessage
                                      name="admin_first_name"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Last Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_last_name"
                                    />
                                    <ErrorMessage
                                      name="admin_last_name"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <Field
                                      type="email"
                                      className="form-control"
                                      name="admin_email"
                                    />
                                    <ErrorMessage
                                      name="admin_email"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Address
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_address"
                                    />
                                    <ErrorMessage
                                      name="admin_address"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">City</label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_city"
                                    />
                                    <ErrorMessage
                                      name="admin_city"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Country
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_country"
                                    />

                                    <ErrorMessage
                                      name="admin_country"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Middle Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_middle_name"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Username
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_username"
                                    />
                                    <ErrorMessage
                                      name="admin_username"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Phone Number
                                    </label>
                                    <Field
                                      type="number"
                                      className="form-control"
                                      name="admin_phone"
                                    />
                                    <ErrorMessage
                                      name="admin_phone"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">State</label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="admin_state"
                                    />
                                    <ErrorMessage
                                      name="admin_state"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Pincode
                                    </label>
                                    <Field
                                      type="number"
                                      className="form-control"
                                      name="admin_zip"
                                    />
                                    <ErrorMessage
                                      name="admin_zip"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center my-3">
                              <button type="submit" className="btn btn-primary">
                                Update User
                              </button>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card sticky-top mb-4 mb-md-0">
                <div className="card-body">
                  <ul
                    className="nav nav-pills flex-column gap-2"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        <i className="bi bi-person me-2"></i> Profile
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link
                        className="nav-link"
                        id="password-tab"
                        data-bs-toggle="tab"
                        to="/changepassword"
                        role="tab"
                        aria-controls="password"
                        aria-selected="false"
                      >
                        <i className="bi bi-lock me-2"></i> Password
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
