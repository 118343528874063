import React from "react";

function SearchCategory({
  handleSearch,
}: {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <div className="d-md-flex gap-4 align-items-center">
        <form className="mb-3 mb-md-0">
          <div className="row">
            <div className="col-md-12">
              <input
                className="form-control"
                placeholder="Search..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SearchCategory;
