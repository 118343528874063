import ReactApexChart from "react-apexcharts";

const BarChart = ({ data }: { data: Array<number> }) => {
  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#05B171", "#FF6E40"],
    },
    series: [
      {
        name: "Orders",
        data: data.map((order: any) => order),
      },
    ],
  };

  return (
    <div className="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
