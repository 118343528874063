import {
  CategoryFormValues,
  CategoryPopulate,
  CategoryPopulateResponse,
  TotalCount,
  UpdateCategoryFormValues,
} from "../type/category";
import axios, { AxiosResponse } from "axios";

import { Category, CategoryResponse } from "./../type/category";
const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfCategories(): Promise<Category[]> {
  const response: AxiosResponse<CategoryResponse> = await axios.get(
    `${API_URL}/categories`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}
export async function populateCategories(): Promise<CategoryPopulate[]> {
  const response: AxiosResponse<CategoryPopulateResponse> = await axios.get(
    `${API_URL}/categories?pageNumber=1&pageSize=1000`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}

export async function getQueryCategories(query: string): Promise<Category[]> {
  const response: AxiosResponse<CategoryResponse> = await axios.get(
    `${API_URL}/categories/search/${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getSingleCategory(id: number) {
  const response = await axios.get(`${API_URL}/categories/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data.output;
}

export async function getCountOfCategory(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/categories/total/count`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
}

export async function addCategory(category: CategoryFormValues) {
  try {
    const response = await axios.post(`${API_URL}/categories`, category, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getLimitedCategory(limit: string) {
  const response = await axios.get(`${API_URL}/categories/limit/${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data.output;
}

export async function getCategoriesByOptions(value: string) {
  const response = await axios.get(`${API_URL}/categories/filters/${value}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data.output;
}

export async function updateCategory(
  id: number,
  data: UpdateCategoryFormValues
) {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const response = await axios.put(`${API_URL}/categories/${id}`, data, {
      headers,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function deleteCategory(id: number) {
  try {
    return await axios
      .delete(`${API_URL}/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        return {
          status: res.status,
          data: res.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response.status,
          data: err.response.data,
        };
      });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete parent category");
  }
}
export async function uploadCategoryImage(categoryId: number, imageFile: File) {
  try {
    const formData = new FormData();
    formData.append("image", imageFile);

    const response = await axios.put(
      `${API_URL}/categories/pic/${categoryId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getCategoresinPagination(
  pageNumber: number,
  pageSize: number
) {
  const response = await axios.get(
    `${API_URL}/categories?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function sortProductsByCategory(categoryId: string) {
  try {
    const response = await axios.get(
      `${API_URL}/products/category/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data.output;
  } catch (error) {}
}
