import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data }: { data: any }) => {
  const chartData = {
    options: {
      chart: {
        id: "basic-line",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#05B171", "#FF6E40"],
    },
    series: [
      {
        name: "Sales",
        data: data.map((sale: any) => sale),
      },
    ],
  };

  return (
    <div className="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
