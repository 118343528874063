import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AccessDeniedWithLoader() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (showLoader) {
    return (
      <div className="d-flex justify-content-center align-items-center align-content-center mt-5">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="content align-items-center content d-flex justify-content-center text-center flex-column">
      <div className="row mb-5">
        <div className="col-md-3 m-auto">
          <figure>
            <img
              className="w-100"
              src="/assets/images/access-denied.png"
              alt="Access Denied"
            />
          </figure>
        </div>
      </div>
      <div className="display-6">Access Denied</div>
      <p className="text-muted my-4">
        You have been denied access to this page. Please check your
        Administrator.
      </p>
      <div className="d-flex gap-3 justify-content-center">
        <Link to="/dashboard" className="btn btn-primary">
          Go To Dashboard
        </Link>
      </div>
    </div>
  );
}

export default AccessDeniedWithLoader;
