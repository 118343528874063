import { UserFormValues, TotalCount, UpdateUserFormValues } from "../type/user";
import { User, UserResponse } from "../type/user";

import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };

// LIST ADMIN
export default async function getListOfUsers(): Promise<User[]> {
  const response: AxiosResponse<UserResponse> = await axios.get(
    `${API_URL}/admin/all`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getSingleUser(id: number) {
  const response = await axios.get(`${API_URL}/parentcategory/${id}`, {
    headers,
  });
  return response.data.output;
}

// COUNT ADMIN
export async function getCountOfUsers(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/parentcategories?filter=count`,
    { headers }
  );
  return response.data;
}

// API FOR ADD ADMIN
export async function addUser(user: UserFormValues) {
  try {
    return await axios
      .post(`${API_URL}/admin`, user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return {
            status: res.status,
            data: res.data,
          };
        }
      })
      .catch((err) => {
        return {
          status: err.response.status,
          data: err.response.data,
        };
      });
  } catch (error) {
    return undefined;
  }
}

export async function updateUser(id: string, user: UpdateUserFormValues) {
  try {
    return await axios
      .patch(`${API_URL}/admin/${id}`, user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return {
            status: res.status,
            data: res.data,
          };
        }
      })
      .catch((err) => {
        return {
          status: err.response.status,
          data: err.response.data,
        };
      });
  } catch (error) {
    return undefined;
  }
}

export async function getLimitedUser(limit: string) {
  const response = await axios.get(
    `${API_URL}/parentcategories?limit=${limit}`,
    {
      headers,
    }
  );
  return response.data.output;
}

export async function getUserByName(limit: string) {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const response = await axios.get(
    `${API_URL}/parentcategories?filter=name&sortBy=ASC&limit=${limit}`,
    {
      headers,
    }
  );
  return response.data.output;
}

export async function deleteUser(id: number) {
  try {
    return await axios
      .delete(`${API_URL}/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return {
            status: res.status,
            data: res.data,
          };
        }
      })
      .catch((err) => {
        return {
          status: err.response.status,
          data: err.response.data,
        };
      });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete user");
  }
}
