import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Footer from "../Fragments/Footer";
import Layout from "../Fragments/Layout";
import Header from "../Fragments/Header";

function ChangePassword() {
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const initialValues = {
    password: "",
    newPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Old password is required"),
    newPassword: Yup.string().required("New password is required"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (values: {
    password: string;
    newPassword: string;
  }) => {
    setIsLoading(true);
    setError("");
    setSuccess(false);

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/change-password`,
          {
            current_password: values.password,
            new_password: values.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);

            values.password = "";
            values.newPassword = "";
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    } catch (error) {
      setError("Failed to change the password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content">
          <div className="container">
            <div className="card">
              <div className="row g-0">
                <div className="col">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div className="my-5 text-center text-lg-start">
                        <h1 className="display-8">Change Password</h1>
                      </div>
                      {success && (
                        <div className="alert alert-success">
                          Password changed successfully!
                        </div>
                      )}
                      {error && (
                        <div className="alert alert-danger">{error}</div>
                      )}
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        <Form className="mb-5">
                          <div className="mb-3">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Enter old password"
                              autoFocus
                              name="password"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="password" component="div" />
                            </div>
                          </div>
                          <div className="mb-3">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Enter new password"
                              name="newPassword"
                            />
                            <div className="text-danger">
                              <ErrorMessage
                                name="newPassword"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="text-center text-lg-start">
                            <p className="small"></p>
                            <button
                              disabled={isLoading}
                              className="btn btn-primary"
                              type="submit"
                            >
                              {isLoading ? "Loading" : "Change Password"}
                            </button>
                          </div>
                        </Form>
                      </Formik>
                      <div className="social-links justify-content-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
