import React, { useState, useEffect } from "react";
import { CustomerSupport } from "../../type/customerSupport";
import { getListOfCustomerSupport } from "../../Services/customerSupport";
import Layout from "../../Fragments/Layout";
import Header from "../../Fragments/Header";
import { Link } from "react-router-dom";
import Loader from "../../Fragments/Loader";
import { convertDateToHumanDate } from "../../helpers/functions";
import Footer from "../../Fragments/Footer";

function ListCustomerSupport() {
  const [loader, setLoader] = useState(true);
  const [customerSupport, setCustomerSupport] = useState<CustomerSupport[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  async function init() {
    setLoader(true);
    const response = await getListOfCustomerSupport();
    if (response.length > 0) {
      setCustomerSupport(response);
      setLoader(false);
    } else {
      setCustomerSupport([]);
      setLoader(false);
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="mb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">Customer Support</li>
              </ol>
            </nav>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className="table-responsive"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={1}
              >
                {loader ? (
                  <Loader />
                ) : (
                  <table
                    className="table table-custom table-lg mb-0 mx-2"
                    id="Categorys"
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Media</th>
                      </tr>
                    </thead>

                    <tbody>
                      {customerSupport.length > 0
                        ? customerSupport.map((output, index) => (
                            <tr key={index}>
                              <td>
                                <Link to={`/user/add/${output.id}`}>
                                  {index + 1}
                                </Link>
                              </td>
                              <td>{output.full_name}</td>
                              <td>{output.email}</td>
                              <td>{output.subject}</td>
                              <td>{output.message}</td>
                              <td>
                                {convertDateToHumanDate(output.created_date)}
                              </td>
                              <td>
                                {output.media.length > 0
                                  ? output.media
                                      .split(",")
                                      .map((image, index) => (
                                        <img
                                          key={index}
                                          src={image}
                                          alt={`Customer Support ${index}`}
                                          style={{
                                            width: "50px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => openModal(image)}
                                          className="mx-1"
                                        />
                                      ))
                                  : "No Media"}
                              </td>
                            </tr>
                          ))
                        : "No Records Found"}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {showModal && (
        <div className="fullscreen-modal" onClick={closeModal}>
          <img src={selectedImage} alt="Fullscreen" />
        </div>
      )}
    </>
  );
}

export default ListCustomerSupport;
