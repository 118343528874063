import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { convertDateToHumanDate } from "../../helpers/functions";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";

import getListOfOrders, {
  getListOfOrderByOrderStatus,
} from "../../Services/orderService";
import { Order } from "../../type/order";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function ListOrders() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState<Order[]>([]);
  const navigate = useNavigate();
  const { ordersModule } = useContext(AuthContext);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [placedCount, setPlacedCount] = useState<number>(0);
  const [confirmedCount, setConfirmedCount] = useState<number>(0);
  const [packedCount, setPackedCount] = useState<number>(0);
  const [outForDeliveryCount, setOutForDeliveryCount] = useState<number>(0);
  const [deliveredCount, setDeliveredCount] = useState<number>(0);
  const [cancelledCount, setCancelledCount] = useState<number>(0);
  const [refundedCount, setRefundedCount] = useState<number>(0);
  const [activeStatus, setActiveStatus] = useState("all");

  async function init() {
    setSuccessMessage("");
    setError("");
    setLoader(true);
    const response = await getListOfOrders();
    if (response.length > 0) {
      setOrder(response);
      setActiveStatus("all");
      setTotalCount(response.length);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  async function getOrderByStatus(e: any, status: string) {
    if (e) {
      e.preventDefault();
    }
    setSuccessMessage("");
    setError("");
    setLoader(true);
    const response = await getListOfOrderByOrderStatus(status);
    if (response.length > 0) {
      setOrder(response);
      if (status === "2") {
        setPlacedCount(response.length);
        setActiveStatus("placed");
      }
      if (status === "3") {
        setConfirmedCount(response.length);
        setActiveStatus("confirmed");
      }
      if (status === "4") {
        setPackedCount(response.length);
        setActiveStatus("packed");
      }
      if (status === "5") {
        setOutForDeliveryCount(response.length);
        setActiveStatus("outForDelivery");
      }
      if (status === "6") {
        setDeliveredCount(response.length);
        setActiveStatus("delivered");
      }
      if (status === "7") {
        setCancelledCount(response.length);
        setActiveStatus("cancelled");
      }
      if (status === "8") {
        setRefundedCount(response.length);
        setActiveStatus("refunded");
      }
      setLoader(false);
    } else {
      setLoader(false);
      setOrder([]);
      if (status === "2") {
        setPlacedCount(response.length);
        setActiveStatus("placed");
      }
      if (status === "3") {
        setConfirmedCount(response.length);
        setActiveStatus("confirmed");
      }
      if (status === "4") {
        setPackedCount(response.length);
        setActiveStatus("packed");
      }
      if (status === "5") {
        setOutForDeliveryCount(response.length);
        setActiveStatus("outForDelivery");
      }
      if (status === "6") {
        setDeliveredCount(response.length);
        setActiveStatus("delivered");
      }
      if (status === "7") {
        setCancelledCount(response.length);
        setActiveStatus("cancelled");
      }
      if (status === "8") {
        setRefundedCount(response.length);
        setActiveStatus("refunded");
      }
      setLoader(false);
    }
  }

  async function handleViewDetails(id: number) {
    navigate(`/order/${id}`);
  }

  useEffect(() => {
    getOrderByStatus(null, "2");
    getOrderByStatus(null, "3");
    getOrderByStatus(null, "4");
    getOrderByStatus(null, "5");
    getOrderByStatus(null, "6");
    getOrderByStatus(null, "7");
    getOrderByStatus(null, "8");
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {ordersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Orders</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="d-flex justify-content-center my-2 mb-5">
                        <button
                          onClick={init}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "all"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          All ({totalCount})
                        </button>

                        <button
                          onClick={(e) => getOrderByStatus(e, "2")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "placed"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Placed ({placedCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "3")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "confirmed"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Confirmed ({confirmedCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "4")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "packed"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Packed ({packedCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "5")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "outForDelivery"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Out for Delivery ({outForDeliveryCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "6")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "delivered"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Delivered ({deliveredCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "7")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "cancelled"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Cancelled ({cancelledCount})
                        </button>
                        <button
                          onClick={(e) => getOrderByStatus(e, "8")}
                          className={`btn btn-primary btn-sm ${
                            activeStatus === "refunded"
                              ? "btn__primary--active"
                              : "bg-white text-orange"
                          } btn__primary mx-2`}
                        >
                          Refunded ({refundedCount})
                        </button>
                      </div>
                      <table
                        className="table table-custom table-lg mb-0 mx-2"
                        id="Orders"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Status</th>
                            <th>Payment Method</th>
                            <th>Amount</th>
                            <th>Address</th>
                            <th>Date</th>
                            <th>User</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {order.length > 0
                            ? order.map((output, index) => (
                                <tr key={index}>
                                  <td>{output?.order_details?.id}</td>
                                  <td>
                                    <span
                                      className={
                                        {
                                          placed: "badge bg-secondary",
                                          confirmed: "badge bg-info",
                                          packed: "badge bg-primary",
                                          "out for delivery": "badge bg-dark",
                                          delivered: "badge bg-success",
                                          cancelled: "badge bg-danger",
                                          refunded: "badge bg-warning",
                                        }[
                                          output?.tracking_details
                                            ?.tracking_status_title
                                        ] || "badge bg-light text-dark"
                                      }
                                    >
                                      {
                                        output?.tracking_details
                                          ?.tracking_status_title
                                      }
                                    </span>
                                  </td>

                                  <td>
                                    {output?.order_details?.payment_method}
                                  </td>
                                  <td>{output?.order_details?.total}</td>
                                  <td>
                                    {
                                      output?.address_details
                                        ?.address_address_one
                                    }
                                  </td>
                                  <td>
                                    {convertDateToHumanDate(
                                      output?.order_details?.created_date
                                    )}
                                  </td>
                                  <td>
                                    {output?.user_details?.user_mobile_number}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        handleViewDetails(
                                          output.order_details.id
                                        )
                                      }
                                    >
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                              ))
                            : "No Records Found"}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListOrders;
