import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { UserFormValues } from "../../type/user";
import { addUser } from "../../Services/userService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const availableModules = [
  {
    module_id: 1,
    module_name: "products",
  },
  {
    module_id: 2,
    module_name: "orders",
  },
  {
    module_id: 3,
    module_name: "customers",
  },
  {
    module_id: 4,
    module_name: "categories",
  },
  {
    module_id: 5,
    module_name: "invoice",
  },
  {
    module_id: 6,
    module_name: "payments",
  },
  {
    module_id: 7,
    module_name: "coupons",
  },
  {
    module_id: 8,
    module_name: "admin",
  },
  {
    module_id: 9,
    module_name: "modules",
  },
  {
    module_id: 10,
    module_name: "tax",
  },
  {
    module_id: 11,
    module_name: "deals",
  },
  {
    module_id: 12,
    module_name: "inventory",
  },
  {
    module_id: 13,
    module_name: "analytics",
  },
  {
    module_id: 14,
    module_name: "admin",
  },
];

const validationSchema = Yup.object({
  admin_first_name: Yup.string().required("First name is required"),
  admin_middle_name: Yup.string().optional(),
  admin_last_name: Yup.string().required("Last name is required"),
  admin_email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  admin_phone: Yup.string()
    .required("Phone number is required")
    .max(10)
    .min(10),
  admin_address: Yup.string().required("Address is required"),
  admin_city: Yup.string().required("City is required"),
  admin_state: Yup.string().required("State is required"),
  admin_zip: Yup.string().required("Zip code is required"),
  admin_country: Yup.string().required("Country is required"),
  admin_username: Yup.string().required("Username is required"),
  admin_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  modules: Yup.array().of(Yup.string()),
});

function AddUser() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { usersModule } = useContext(AuthContext);

  const initialValues: UserFormValues = {
    admin_first_name: "",
    admin_middle_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_address: "",
    admin_city: "",
    admin_state: "",
    admin_zip: "",
    admin_country: "",
    admin_username: "",
    admin_password: "",
    modules: [],
  };

  const handleSubmit = async (
    values: UserFormValues,
    formikHelpers: FormikHelpers<UserFormValues>
  ) => {
    try {
      setLoading(true);
      const response = await addUser(values);
      if (response?.status === 200) {
        setSuccessMessage(response.data.message);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
      } else {
        setError(response?.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (error) {
      setError("An error ");
      setTimeout(() => {
        setError("");
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {usersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className=" col-md-12">
              <div className="card mb-4">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="checkout-form-wizard"
                        role="application"
                        className="wizard clearfix"
                      >
                        <div className="content clearfix">
                          <h3
                            id="checkout-form-wizard-h-0"
                            tabIndex={-1}
                            className="title current"
                          >
                            Add User
                          </h3>
                          {successMessage !== "" ? (
                            <div className="alert alert-success" role="alert">
                              {successMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          {errorMessage !== "" ? (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                          >
                            {({ values, setFieldValue }) => (
                              <Form>
                                <div className="row g-4 mb-3">
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">First Name</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter first name"
                                        type="text"
                                        name="admin_first_name"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_first_name" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Middle Name
                                      </label>
                                      <Field
                                        className="form-control"
                                        placeholder=" Enter middle name"
                                        type="text"
                                        name="admin_middle_name"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_middle_name" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">Last Name</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter last name"
                                        type="text"
                                        name="admin_last_name"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_last_name" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-4 mb-3">
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3">User Name</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter user name"
                                        type="text"
                                        name="admin_username"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_username" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3">Email</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter email"
                                        type="email"
                                        name="admin_email"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_email" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row g-4 mb-3">
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Phone Number
                                      </label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter phone number"
                                        type="text"
                                        name="admin_phone"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_phone" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3"> Adress</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter adress"
                                        type="text"
                                        name="admin_address"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_address" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-4 mb-3">
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">Pincode</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter pincode"
                                        type="text"
                                        name="admin_zip"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_zip" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3"> State</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Select state"
                                        type="date"
                                        name="admin_state"
                                        as="select"
                                      >
                                        <option value="">State</option>
                                        <option value="jammu and kashmir">
                                          Kashmir
                                        </option>
                                      </Field>

                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_state" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">City</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Select city"
                                        type="date"
                                        name="admin_city"
                                        as="select"
                                      >
                                        <option value="">City</option>
                                        <option value="srinagar">
                                          Srinagar
                                        </option>
                                      </Field>

                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_city" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-4 mb-3">
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3"> Country</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Select country"
                                        type="date"
                                        name="admin_country"
                                        as="select"
                                      >
                                        <option value="">Country</option>

                                        <option value="india">India</option>
                                      </Field>
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_country" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="my-2">
                                      <label className="pb-3"> Password</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter password"
                                        name="admin_password"
                                      ></Field>
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="admin_password" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="content clearfix">
                                  <h3
                                    id="checkout-form-wizard-h-0"
                                    tabIndex={-1}
                                    className="title current"
                                  >
                                    Modules
                                  </h3>

                                  <div className="row g-4 mb-3">
                                    {availableModules.map((module) => (
                                      <div
                                        className="col-md-3"
                                        key={module.module_id}
                                      >
                                        <div className="my-3">
                                          <div className="form-check">
                                            <Field
                                              type="checkbox"
                                              className="form-check-input"
                                              name="modules"
                                              id={`${module.module_id}`}
                                              value={module.module_id}
                                              checked={values.modules.includes(
                                                module.module_id
                                              )}
                                              onChange={() => {
                                                const newModules =
                                                  values.modules.includes(
                                                    module.module_id
                                                  )
                                                    ? values.modules.filter(
                                                        (id) =>
                                                          id !==
                                                          module.module_id
                                                      )
                                                    : [
                                                        ...values.modules,
                                                        module.module_id,
                                                      ];
                                                setFieldValue(
                                                  "modules",
                                                  newModules
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${module.module_id}`}
                                            >
                                              {module.module_name
                                                .charAt(0)
                                                .toUpperCase() +
                                                module.module_name.slice(1)}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Add User
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AddUser;
