import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";
import {
  convertDateToHumanDate,
  convertFirstLetterToUpperCase,
} from "../../helpers/functions";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import { Coupon } from "../../type/coupon";
import getListOfCoupon, {
  deleteCoupon,
  getCouponsByFilter,
  searchCoupon,
} from "../../Services/couponService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function ListCoupon() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [coupon, setCoupon] = useState<Coupon[]>([]);
  const { couponModule } = useContext(AuthContext);
  const [count, setCount] = useState<number>(0);
  const [selectedLimit, setSelectedLimit] = useState<string>("10");
  const [selectedOption, setSelectedOption] = useState<string>("");

  async function handleDelete(id: number) {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) {
      return;
    }
    await deleteCoupon(id);
    init();
    setSuccessMessage("Coupon Deleted Successfully");
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
    setError("");
    setSelectedOption("");
  }

  async function handleLimit(e: string) {
    setSelectedLimit(e);
  }

  const [page, setPage] = useState(1);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const totalPages = Math.ceil(count / Number(selectedLimit));

  const MAX_PAGE_DISPLAY = Number(selectedLimit);

  const renderPageLinks = () => {
    const startPage = Math.max(1, page - Math.floor(MAX_PAGE_DISPLAY / 2));
    const endPage = Math.min(totalPages, startPage + MAX_PAGE_DISPLAY - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  async function handleSearch(e: any) {
    if (e.target.value === "") {
      return init();
    }
    const data = await e.target.value;
    if (data !== undefined && data.length > 0) {
      setCoupon(await searchCoupon(e.target.value));
    }
  }

  async function handleOptions(e: any) {
    if (e.target.value === "asc") {
      const response = await getCouponsByFilter(e.target.value);
      if (response.length > 0) {
        setCoupon(response);
      }
    }
    if (e.target.value === "desc") {
      const response = await getCouponsByFilter(e.target.value);
      if (response.length) {
        setCoupon(response);
      }
    }
    if (e.target.value === "latest") {
      const response = await getCouponsByFilter(e.target.value);
      if (response.length) {
        setCoupon(response);
      }
    }
    if (e.target.value === "expired") {
      const response = await getCouponsByFilter(e.target.value);
      if (response.length) {
        setCoupon(response);
      }
    }
  }

  async function init() {
    setLoader(true);
    const response = await getListOfCoupon(page, Number(selectedLimit));
    if (response.output.total > 0) {
      setCoupon(response.output.data);
      setCount(response.output.total);
      setLoader(false);
    } else {
    }
  }

  useEffect(() => {
    init();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {couponModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Coupons</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {count} Coupons
                      </div>
                      <div className="d-md-flex gap-4 align-items-center">
                        <form className="mb-3 mb-md-0">
                          <div className="row">
                            <div className="col-md-6">
                              <select
                                onChange={(e) => handleOptions(e)}
                                name="kt_ecommerce_products_table_length"
                                aria-controls="kt_ecommerce_products_table"
                                className="form-select"
                                defaultValue={selectedOption}
                              >
                                <option>Sort by</option>
                                <option value="latest">Latest</option>
                                <option value="desc">Desc</option>
                                <option value="asc">Asc</option>
                                <option value="inactive">Expired</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <div className="col-md-12">
                                <select
                                  onChange={(e) => handleLimit(e.target.value)}
                                  name="kt_ecommerce_products_table_length"
                                  aria-controls="kt_ecommerce_products_table"
                                  className="form-select"
                                  defaultValue={selectedLimit}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="d-md-flex gap-4 align-items-center">
                        <form className="mb-3 mb-md-0">
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                placeholder="Search..."
                                onChange={(e) => handleSearch(e)}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className=" ms-auto">
                        <Link
                          to="/coupon/add"
                          className="btn btn-primary "
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add Coupon
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <nav
                        className="mt-4"
                        aria-label="Page navigation example"
                      >
                        <ul className="pagination justify-content-center">
                          <li
                            className={`page-item ${
                              page === 1 ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="btn btn-sm btn-primary mx-2"
                              onClick={handlePrevPage}
                              disabled={page === 1}
                            >
                              Previous Page
                            </button>
                          </li>
                          {renderPageLinks().map((pageNum) => (
                            <li
                              className={`page-item ${
                                pageNum === page ? "active" : ""
                              }`}
                              key={pageNum}
                            >
                              <button
                                className="page-link"
                                onClick={() => setPage(pageNum)}
                              >
                                {pageNum}
                              </button>
                            </li>
                          ))}
                          <li
                            className={`page-item ${
                              page === totalPages ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="btn btn-sm btn-primary mx-2"
                              onClick={handleNextPage}
                              disabled={page === totalPages}
                            >
                              Next Page
                            </button>
                          </li>
                        </ul>
                      </nav>
                      <table
                        className="table table-custom table-lg mb-0 mx-2"
                        id="Categorys"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Coupon Code</th>
                            <th>Max Uses</th>
                            <th>Current Uses</th>
                            <th>Active</th>
                            <th>Start Date</th>
                            <th>Expiry Date</th>
                            <th>Created By</th>
                            <th>Updated By</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {count > 0
                            ? coupon.map((output, index) => (
                                <tr key={index}>
                                  <td>
                                    <Link to={`/user/add/${output.coupon_id}`}>
                                      {index + 1}
                                    </Link>
                                  </td>

                                  <td>{output.coupon_title}</td>

                                  <td>{output.coupon_code}</td>
                                  <td>{output.coupon_max_uses}</td>
                                  <td>{output.coupon_current_uses}</td>

                                  <td
                                    style={{
                                      color:
                                        output.coupon_active === 1
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {output.coupon_active === 1 ? "Yes" : "No"}
                                  </td>

                                  <td>
                                    {convertDateToHumanDate(
                                      output.coupon_start_date
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        new Date(output.coupon_end_date) <
                                        new Date()
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {new Date(output.coupon_end_date) <
                                    new Date()
                                      ? "Expired"
                                      : convertDateToHumanDate(
                                          output.coupon_end_date
                                        )}
                                  </td>

                                  <td>
                                    {convertFirstLetterToUpperCase(
                                      output.coupon_created_by
                                    )}
                                  </td>

                                  <td>
                                    {convertFirstLetterToUpperCase(
                                      output.coupon_updated_by
                                    )}
                                  </td>
                                  <td className="text-end">
                                    <div className="d-flex">
                                      <Link to={`/coupon/${output.coupon_id}`}>
                                        <i className="bi bi-pencil small me-2 customIcon mx-3"></i>
                                      </Link>

                                      <i
                                        onClick={() =>
                                          handleDelete(output.coupon_id)
                                        }
                                        className="bi bi-trash small me-2 customIcon mx-3"
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : "No Records Found"}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListCoupon;
