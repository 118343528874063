import {
  ParentCategoryFormValues,
  TotalCount,
  UpdateParentCategoryFormValues,
} from "../type/ParentCategory";
import axios, { AxiosResponse } from "axios";

import { ParentCategory, ParentCategoryResponse } from "../type/ParentCategory";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfParentCategories(
  pageNumber: number,
  limit: number
): Promise<ParentCategory[]> {
  const response: AxiosResponse<ParentCategoryResponse> = await axios.get(
    `${API_URL}/categories/parent-category?pageNumber=${pageNumber}&pageSize=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}

export async function getQueryParentCategories(
  query: string
): Promise<ParentCategory[]> {
  const response: AxiosResponse<ParentCategoryResponse> = await axios.get(
    `${API_URL}/categories/parent-category/search/${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getSingleParentCategory(id: number) {
  const response = await axios.get(
    `${API_URL}/categories/parent-category/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getCountOfParentCategory(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/categories/parent-category/total/count`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
}

export async function addParentCategory(
  parentcategory: ParentCategoryFormValues
) {
  try {
    const formData = new FormData();
    formData.append("name", parentcategory.name);

    if (parentcategory.image) {
      formData.append("image", parentcategory.image);
    }

    const response = await axios.post(
      `${API_URL}/categories/parent-category`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getParentCategoriesByFilter(filter: string) {
  return await axios
    .get(`${API_URL}/categories/parent-category/filters/${filter}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        if (response.data) {
          return {
            status: true,
            data: response.data.output
              ? response.data.output
              : response.data.message,
          };
        }
      }
    })
    .catch((error) => {
      return {
        status: false,
        data: error,
      };
    });
}

export async function updateParentCategory(
  id: number,
  data: UpdateParentCategoryFormValues
) {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.put(
      `${API_URL}/categories/parent-category/${id}`,
      data,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function deleteParentCategory(id: number) {
  try {
    return await axios
      .delete(`${API_URL}/categories/parent-category/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  } catch (error) {
    throw new Error("Failed to delete parent category");
  }
}

export async function uploadParentCategoryImage(
  parentCategoryId: number,
  imageFile: File
) {
  try {
    const formData = new FormData();
    formData.append("image", imageFile);

    const response = await axios.put(
      `${API_URL}/categories/parent-category/pic/${parentCategoryId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {}
}

export async function populateParentCategories() {
  const response = await axios.get(
    `${API_URL}/categories/parent-category?pageNumber=1&pageSize=10000`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}
