import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { QuickUpdateProductFormValues } from "../../type/product";
import * as Yup from "yup";

import { quickUpdateProduct } from "../../Services/productService";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),

  max_price: Yup.number()
    .min(
      Yup.ref("sales_price"),
      "Maximum price must be greater than or equal to minimum price"
    )
    .required("Maximum price is required"),
  sales_price: Yup.number()
    .min(0, "Sales price must be a positive number")
    .required("Sales price is required"),
});

function QuickUpdateProduct({
  id,
  name,
  max_price,
  sales_price,
  onClose,
  show,
}: {
  id: number;
  name: string;
  max_price: number;
  sales_price: number;
  onClose: () => void;
  show: boolean;
}) {
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [errorMessage, setError] = useState<string>("");

  const [initialValues, setInitialValues] =
    useState<QuickUpdateProductFormValues>({
      name: "",
      max_price: 0,
      sales_price: 0,
    });

  interface AxiosResponseIF {
    status: number;
    data: any;
  }

  const handleSubmit = async (values: QuickUpdateProductFormValues) => {
    try {
      values.max_price = Number(values.max_price);
      values.sales_price = Number(values.sales_price);

      const response: AxiosResponseIF = await quickUpdateProduct(id, values);
      if (response.status === 400) {
        setError(response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
        return;
      }
      if (response) {
        setSuccessMessage("Updated Successfully");
        window.scrollTo(0, 0);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        // window.location.reload();
      } else {
        setError("An error occurred");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (error) {
      setError("An error Occurred");
    }
  };

  useEffect(() => {
    setInitialValues({
      name: name,
      max_price: max_price,
      sales_price: sales_price,
    });
  }, [id, name, max_price, sales_price]);

  return (
    <>
      <div className="modal show" style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Product</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              {name.length > 0 ? (
                <div className="content ">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="checkout-form-wizard"
                        role="application"
                        className="wizard clearfix"
                      >
                        <div className="content clearfix">
                          {successMessage !== "" ? (
                            <div className="alert alert-success" role="alert">
                              {successMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          {errorMessage !== "" ? (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          {initialValues.name.length > 3 ? (
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={handleSubmit}
                            >
                              {({ values, setFieldValue }) => (
                                <Form
                                  onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                  }
                                >
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3"> Name</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter Product Name"
                                          type="text"
                                          name="name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="name" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Sales Price
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter min price "
                                          type="text"
                                          name="sales_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="sales_price" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">MRP</label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter max price"
                                          type="text"
                                          name="max_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="max_price" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Update Product
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickUpdateProduct;
