import {
  Notification,
  NotificationFormValues,
  NotificationResponse,
} from "../type/notification";

import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfNotification(): Promise<Notification[]> {
  const response: AxiosResponse<NotificationResponse> = await axios.get(
    `${API_URL}/notifications`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}

export async function addNotification(notification: NotificationFormValues) {
  try {
    const response = await axios.post(
      `${API_URL}/notifications`,
      notification,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}
