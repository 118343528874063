import { useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { ProductVariationFormValues } from "../../type/product";
import { updateVariation } from "../../Services/productService";

function UpdateProductVariations({ show, onClose, variation }: any) {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  if (!show) {
    return null;
  }

  console.log(variation);

  const initialValues: ProductVariationFormValues = {
    name: variation.name,
    description: variation.description,
    price: variation.price,
    max_price: variation.max_price,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string(),
    price: Yup.number().required("Required"),
    max_price: Yup.number().required("Required"),
  });

  const handleSubmit = async (
    values: ProductVariationFormValues,
    formikHelpers: FormikHelpers<ProductVariationFormValues>
  ) => {
    try {
      const response = await updateVariation(
        Number(variation.id),
        values.name,
        values.price,
        values.max_price
      );
      if (response?.status === 200) {
        setSuccessMessage("Product variation Updated successfully!");
        setLoading(false);
        setError("");
        window.location.reload();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        formikHelpers.resetForm();
      } else {
        setLoading(false);
        setError("An error occurred while adding the product");
      }
    } catch (error) {
      setError("An error occurred while adding the product");
    }
  };

  return (
    <>
      <div className="modal show" style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Variations</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="content row ">
                <div className=" col-md-12">
                  <div className="card mb-4">
                    <div className="card-body ">
                      {variation.name && variation.name.length > 0 ? (
                        <div className="row">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                          >
                            {({ values }) => (
                              <Form>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      id="checkout-form-wizard"
                                      role="application"
                                      className="wizard clearfix"
                                    >
                                      <div className="content clearfix">
                                        {successMessage && (
                                          <div
                                            className="alert alert-success"
                                            role="alert"
                                          >
                                            {successMessage}
                                          </div>
                                        )}
                                        {errorMessage && (
                                          <div
                                            className="alert alert-danger"
                                            role="alert"
                                          >
                                            {errorMessage}
                                          </div>
                                        )}

                                        <div className="row g-4 mb-3">
                                          <div className="col-md-12">
                                            <div className="my-2">
                                              <label className="pb-3">
                                                Variation Name
                                              </label>
                                              <Field
                                                className="form-control"
                                                placeholder=" Enter variation name"
                                                type="text"
                                                name="name"
                                              />
                                              <div style={{ color: "red" }}>
                                                <ErrorMessage name="name" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="my-2">
                                              <label className="pb-3">
                                                Price
                                              </label>
                                              <Field
                                                className="form-control"
                                                placeholder=" Enter Price "
                                                type="number"
                                                name="price"
                                              />
                                              <div style={{ color: "red" }}>
                                                <ErrorMessage name="price" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="my-2">
                                              <label className="pb-3">
                                                Max Price
                                              </label>
                                              <Field
                                                className="form-control"
                                                placeholder=" Enter Price "
                                                type="number"
                                                name="max_price"
                                              />
                                              <div style={{ color: "red" }}>
                                                <ErrorMessage name="max_price" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12">
                                            <div className="my-2">
                                              <label className="pb-3">
                                                Description
                                              </label>
                                              <Field
                                                className="form-control"
                                                placeholder=" Enter Description"
                                                type="text"
                                                name="description"
                                              />
                                              <div style={{ color: "red" }}>
                                                <ErrorMessage name="description" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-center my-3">
                                          <button
                                            disabled={loading}
                                            type="submit"
                                            className="btn btn-primary"
                                          >
                                            Update Variation
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="alert alert-danger" role="alert">
                              No Variation Found
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateProductVariations;
