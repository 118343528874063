import {
  AddBannerFormValues,
  TotalCount,
  UpdateDealFormValues,
} from "../type/banner";
import { Banner, BannerResponse } from "../type/banner";
import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfBanners(): Promise<Banner[]> {
  const response: AxiosResponse<BannerResponse> = await axios.get(
    `${API_URL}/banners`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  if (response.data.output) {
    return response.data.output;
  } else {
    return [];
  }
}

export async function getCountOfDeal(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/parentcategories?filter=count`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data;
}

export async function addBanner(image: any, banner: AddBannerFormValues) {
  try {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("bannerType", banner.bannerType);
    formData.append("bannerLocation", banner.bannerLocation);
    formData.append("categoryId", banner.categoryId.toString());
    formData.append("productId", banner.productId.toString());

    const response = await axios.post(`${API_URL}/banners`, formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return undefined;
  }
}

export async function updateBannerImage(id: string, image: any) {
  try {
    const formData = new FormData();
    formData.append("image", image);

    const response = await axios.post(
      `${API_URL}/banners/upload-image/${id.toString()}`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getLimitedDeal(limit: string) {
  const response = await axios.get(
    `${API_URL}/parentcategories?limit=${limit}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data.output;
}

export async function getDealByName(limit: string) {
  const response = await axios.get(
    `${API_URL}/parentcategories?filter=name&sortBy=ASC&limit=${limit}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data.output;
}

export async function updateDeal(id: number, data: UpdateDealFormValues) {
  try {
    const response = await axios.put(`${API_URL}/parentcategory/${id}`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function deleteCategory(id: number) {
  try {
    await axios.delete(`${API_URL}/categories/${id}`);
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete category");
  }
}
