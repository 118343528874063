import {
  CouponFormValues,
  TotalCount,
  UpdateCouponFormValues,
} from "../type/coupon";

import { CouponResponse } from "../type/coupon";

import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// LIST COUPON
export default async function getListOfCoupon(
  pageNumber: number,
  pageSize: number
) {
  const response: AxiosResponse<CouponResponse> = await axios.get(
    `${API_URL}/coupons?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data;
}

export async function getSingleCoupon(id: number) {
  const response = await axios.get(`${API_URL}/coupons/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data.output;
}

// COUNT COUPON
export async function getCountOfCoupon(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/parentcategories?filter=count`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data;
}

// API FOR ADD COUPON

export async function addCoupon(product: CouponFormValues) {
  try {
    const response = await axios.post(`${API_URL}/coupons`, product, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getLimitedCoupon(limit: string) {
  const response = await axios.get(
    `${API_URL}/parentcategories?limit=${limit}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data.output;
}

export async function getCouponByName(limit: string) {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const response = await axios.get(
    `${API_URL}/parentcategories?filter=name&sortBy=ASC&limit=${limit}`,
    {
      headers,
    }
  );
  return response.data.output;
}

export async function updateCoupon(id: number, data: UpdateCouponFormValues) {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const response = await axios.put(`${API_URL}/coupons/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function deleteCoupon(id: number) {
  try {
    await axios.delete(`${API_URL}/coupons/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete coupon");
  }
}

export async function searchCoupon(name: string) {
  try {
    const response = await axios.get(`${API_URL}/coupons/search/${name}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.output;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to search coupon");
  }
}

export async function getCouponsByFilter(filter: string) {
  const response = await axios.get(`${API_URL}/coupons/filter/${filter}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data.output;
}
