import axios from "axios";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import Cookies from "universal-cookie";

function CpuUsage() {
  const [cpuUsage, setCpuUsage] = useState<number>(0);

  useEffect(() => {
    const fetchCpuUsage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/statistics/usage/cpu`,
          {
            headers: {
              Authorization: `Bearer ${new Cookies().get("token")}`,
            },
          }
        );
        setCpuUsage(parseFloat(response.data.output.replace("%", "")) / 100);
      } catch (err) {
        console.error("Error fetching CPU usage:", err);
        setCpuUsage(0);
      }
    };

    fetchCpuUsage();
  }, []);

  return (
    <GaugeChart
      id="gauge-chart4"
      nrOfLevels={10}
      arcPadding={0.1}
      cornerRadius={3}
      percent={cpuUsage}
    />
  );
}

export default CpuUsage;
