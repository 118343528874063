import axios, { AxiosResponse } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { LoginForm, LoginResponse } from "../type/login";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useAuth } from "../context/authContext";
import Cookies from "universal-cookie";

const API_URL = process.env.REACT_APP_API_URL;

function Login() {
  const cookies = new Cookies();
  const initialValues: LoginForm = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const {
    setLoggedIn,
    setProductsModule,
    setCategoriesModule,
    setUsersModule,
    setAdminModules,
    setOrdersModule,
    setCouponModule,
    setTaxModule,
    setDealsModule,
    setAnalyticsModule,
    setInventoryModule,
    setInvoiceModule,
    setPaymentModule,
    setAdminModule,
    setAccessModule,
  } = useAuth();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const handleSubmit = async (values: LoginForm) => {
    setIsLoading(true);
    setError("");
    setSuccess(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    try {
      const response: AxiosResponse<LoginResponse> = await axios.post(
        `${API_URL}/auth/login`,
        values
      );

      const productsAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "products"
      );
      const categoryAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "categories"
      );
      const usersAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "customers"
      );
      const adminAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "admin"
      );
      const ordersAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "orders"
      );
      const couponAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "coupons"
      );
      const taxAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "tax"
      );
      const dealsAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "deals"
      );
      const analyticsAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "analytics"
      );
      const inventoryAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "inventory"
      );
      const invoiceAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "invoice"
      );
      const paymentAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "payments"
      );
      const adminModuleAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "admin"
      );
      const accessModuleAccess = response.data.output.modules.find(
        (m) => m["module_name"] === "modules"
      );

      setCategoriesModule(
        categoryAccess && categoryAccess["module_access"] === "1" ? true : false
      );

      setUsersModule(
        usersAccess && usersAccess["module_access"] === "1" ? true : false
      );

      setAdminModules(
        adminAccess && adminAccess["module_access"] === "1" ? true : false
      );

      setOrdersModule(
        ordersAccess && ordersAccess["module_access"] === "1" ? true : false
      );

      setCouponModule(
        couponAccess && couponAccess["module_access"] === "1" ? true : false
      );

      setTaxModule(
        taxAccess && taxAccess["module_access"] === "1" ? true : false
      );

      setDealsModule(
        dealsAccess && dealsAccess["module_access"] === "1" ? true : false
      );

      setAnalyticsModule(
        analyticsAccess && analyticsAccess["module_access"] === "1"
          ? true
          : false
      );

      setInventoryModule(
        inventoryAccess && inventoryAccess["module_access"] === "1"
          ? true
          : false
      );

      setInvoiceModule(
        invoiceAccess && invoiceAccess["module_access"] === "1" ? true : false
      );

      setPaymentModule(
        paymentAccess && paymentAccess["module_access"] === "1" ? true : false
      );

      setAdminModule(
        adminModuleAccess && adminModuleAccess["module_access"] === "1"
          ? true
          : false
      );

      setAccessModule(
        accessModuleAccess && accessModuleAccess["module_access"] === "1"
          ? true
          : false
      );

      const token = response.data.output.token;
      localStorage.setItem("token", token);
      setLoggedIn(true);
      setSuccess(true);
      setProductsModule(
        productsAccess && productsAccess["module_access"] === "1" ? true : false
      );
      setCategoriesModule(
        categoryAccess && categoryAccess["module_access"] === "1" ? true : false
      );
      setUsersModule(
        usersAccess && usersAccess["module_access"] === "1" ? true : false
      );
      setAdminModules(
        adminAccess && adminAccess["module_access"] === "1" ? true : false
      );
      setOrdersModule(
        ordersAccess && ordersAccess["module_access"] === "1" ? true : false
      );
      setCouponModule(
        couponAccess && couponAccess["module_access"] === "1" ? true : false
      );
      setTaxModule(
        taxAccess && taxAccess["module_access"] === "1" ? true : false
      );
      setDealsModule(
        dealsAccess && dealsAccess["module_access"] === "1" ? true : false
      );
      setAnalyticsModule(
        analyticsAccess && analyticsAccess["module_access"] === "1"
          ? true
          : false
      );
      setInventoryModule(
        inventoryAccess && inventoryAccess["module_access"] === "1"
          ? true
          : false
      );
      setInvoiceModule(
        invoiceAccess && invoiceAccess["module_access"] === "1" ? true : false
      );
      setPaymentModule(
        paymentAccess && paymentAccess["module_access"] === "1" ? true : false
      );
      setAdminModule(
        adminModuleAccess && adminModuleAccess["module_access"] === "1"
          ? true
          : false
      );
      setAccessModule(
        accessModuleAccess && accessModuleAccess["module_access"] === "1"
          ? true
          : false
      );

      cookies.set("token", token, { path: "/" });
      new Notification("Welcome Back, Please check your notifications");
      getPaymentStatus();
      console.log(paymentStatus);
      // if (paymentStatus) {
      //   alert("Please Pay Your Maintenance Charges");
      // }
      // navigate("/payment");
      navigate("/dashboard");
    } catch (error: any) {
      setError(error.response?.data?.message || "Something went wrong");
    }
  };

  async function getPaymentStatus() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/payment-status`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => setPaymentStatus(res.data.output))
      .catch((err) => setPaymentStatus(false));
  }

  useEffect(() => {
    function requestNotificationPermission() {
      if (!("Notification" in window)) {
      } else if (Notification.permission === "granted") {
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
          }
        });
      }
    }
    requestNotificationPermission();
  }, []);

  return (
    <>
      <div
        className="form-wrapper"
        style={{ padding: "180px 0px", backgroundColor: "#f17012" }}
      >
        <div className="container">
          <div className="card">
            <div className="row g-0">
              <div className="col">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="d-block d-lg-none text-center text-lg-start">
                      <img
                        width="120"
                        src="assets/images/logo.svg"
                        alt="logo"
                      />
                    </div>
                    <div className="my-5 text-center text-lg-start">
                      <h1 className="display-8">Sign In</h1>
                      <p className="text-muted">MCL Admin Panel</p>
                    </div>
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form className="mb-5">
                        <div className="mb-3">
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            autoFocus
                            name="email"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="email" component="div" />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            name="password"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>

                        <div className="text-center text-lg-start">
                          <p className="small"></p>
                          <button
                            disabled={isLoading}
                            className="btn btn-primary"
                            type="submit"
                          >
                            {isLoading ? "Loading" : "Log In"}
                          </button>
                        </div>
                      </Form>
                    </Formik>
                    <div className="social-links justify-content-center"></div>
                  </div>
                </div>
              </div>
              <div className="col d-none d-lg-flex border-start align-items-center justify-content-between flex-column text-center">
                <div className="logo mt-md-5"></div>
                <div>
                  <img
                    src="https://wallayl.com/assets/img/logo.png"
                    alt="Display-square"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h3 className="fw-bold">
                    <span style={{ fontSize: "16px" }}>Powered by</span>{" "}
                    <a style={{ color: "#f17012" }} href="https://wallayl.com/">
                      Wal Layl Private Limited
                    </a>
                  </h3>
                  <p className="lead my-5">
                    For any queries and customer support
                  </p>
                  <Link to="https://wallayl.com" className="btn btn-primary">
                    Call Now
                  </Link>
                </div>
                <ul className="list-inline mb-md-5">
                  <li className="list-inline-item"></li>
                  <li className="list-inline-item"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
