interface CategoryPaginationProps {
  handlePrevPage: () => void;
  handleNextPage: () => void;
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
}

function CategoryPagination({
  handlePrevPage,
  handleNextPage,
  page,
  totalPages,
  setPage,
}: CategoryPaginationProps) {
  const pageLinks = Array.from({ length: totalPages }, (_, index) => index + 1);

  function handlePageNumber(e: any) {
    e.preventDefault();
    setPage(e.target.textContent);
  }

  return (
    <>
      <nav className="mt-4" aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {pageLinks.map((item) => (
            <li
              className={`page-item ${item === Number(page) ? "active" : ""}`}
              key={item}
            >
              <button
                className="page-link mx-1"
                onClick={(e) => handlePageNumber(e)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>

        {/* <button onClick={handlePrevPage} disabled={page === 1}>
          Previous Page
        </button>
        <button
          className="page-item"
          onClick={handleNextPage}
          disabled={page === totalPages}
        >
          Next Page
        </button> */}
      </nav>
    </>
  );
}

export default CategoryPagination;
