import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import {
  deleteProductVariation,
  getListOfProductVariants,
} from "../../Services/productService";
import { ProductVariant } from "../../type/product";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";
import UpdateProductVariations from "./UpdateProductVariations";

function ListVariations() {
  const { productsModule } = useContext(AuthContext);
  const [count, setCount] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [productVariations, setProductVariations] = useState<ProductVariant[]>(
    []
  );
  const param = useParams<{ id: string }>();
  const [updatePopUp, setUpdatePopUp] = useState({
    show: false,
    product: null,
  });

  async function init() {
    setLoader(true);
    const response = await getListOfProductVariants(Number(param.id));
    if (response.length > 0) {
      setProductVariations(response);
      setCount(response.totalCount);
      setError("");
      setLoader(false);
    } else {
      setProductVariations([]);
      setLoader(false);
    }
  }

  async function handleDelete(id: number) {
    const response = await deleteProductVariation(id);
    if (response?.message) {
      setSuccessMessage(response.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    }
    init();
  }

  useEffect(() => {
    async function init() {
      setLoader(true);
      const response = await getListOfProductVariants(Number(param.id));
      if (response.length > 0) {
        setProductVariations(response);
      } else {
        setProductVariations([]);
      }
      setLoader(false);
    }

    init();
  }, [param.id]);

  const handleOpenModal = (product: any) => {
    setUpdatePopUp({
      show: true,
      product: product,
    });
  };

  const handleCloseModal = () => {
    setUpdatePopUp({
      show: false,
      product: null,
    });
  };

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Product Varations</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {count} Variations
                      </div>

                      <div className=" ms-auto">
                        <Link
                          to={`/products/variations/add/${param.id}`}
                          className="btn btn-primary "
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add New Variation
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <table className="table table-custom table-lg mb-0 mx-2">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Max Price</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {productVariations.length > 0 ? (
                            productVariations.map((output, index) => (
                              <tr key={index}>
                                <td>{output.id}</td>
                                <td>{output.name}</td>
                                <td>{output.price}</td>
                                <td>{output.max_price}</td>
                                <td>
                                  {output.description &&
                                  output.description.length === 0
                                    ? output.description
                                    : "No Description Available"}
                                </td>

                                <td className="text-end">
                                  <div className="d-flex">
                                    <i
                                      onClick={() => handleOpenModal(output)}
                                      className="bi bi-pencil small me-2 customIcon mx-3"
                                    ></i>
                                    <i
                                      onClick={() => handleDelete(output.id)}
                                      className="bi bi-trash small me-2 customIcon mx-3"
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {updatePopUp && (
                        <UpdateProductVariations
                          show={updatePopUp.show}
                          onClose={handleCloseModal}
                          variation={updatePopUp.product}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListVariations;
