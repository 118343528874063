import axios from "axios";

export function getListOfCustomerSupport() {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/customer-support`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      return response.data.output;
    })
    .catch((error) => {
      return error;
    });
}
