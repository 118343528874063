import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";

import { UpdateCouponFormValues } from "../../type/coupon";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getSingleCoupon, updateCoupon } from "../../Services/couponService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function UpdateCoupon() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { couponModule } = useContext(AuthContext);

  // const [selectedImage, setSelectedImage] = useState(null);

  const [initialValues, setInitialValues] = useState<UpdateCouponFormValues>({
    title: "",
    description: "",
    code: "",
    discount_amount: 0,
    max_uses: 0,
    current_uses: 0,
  });
  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(2, "Title must be at least 2 characters")
      .max(255, "Title cannot exceed 255 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .max(500, "Description cannot exceed 500 characters"),
    code: Yup.string()
      .required("Code is required")
      .matches(
        /^[A-Z0-9]+$/,
        "Code must contain only uppercase letters and numbers"
      ),
    active: Yup.boolean().required("Active status is required"),
    discount_amount: Yup.number()
      .required("Discount amount is required")
      .positive("Discount amount must be a positive number")
      .max(100, "Discount amount cannot exceed 100%"),
    max_uses: Yup.number()
      .required("Maximum uses is required")
      .integer("Maximum uses must be an integer")
      .min(0, "Maximum uses cannot be negative"),
    current_uses: Yup.number()
      .required("Current uses is required")
      .integer("Current uses must be an integer")
      .min(0, "Current uses cannot be negative"),
  });

  const param = useParams();
  const couponId = Number(param.id);

  async function init() {
    await getSingleCoupon(couponId);
  }

  async function getCategory(couponId: number) {
    const response = await getSingleCoupon(couponId);
    const formattedData: UpdateCouponFormValues = {
      title: response[0].parent_category_name,
      description: response[0].parent_category_active,
      code: "",
      discount_amount: 0,
      max_uses: 0,
      current_uses: 0,
    };

    setInitialValues(formattedData);
  }

  const handleSubmit = async (values: UpdateCouponFormValues) => {
    try {
      setLoading(true);
      const response = await updateCoupon(couponId, values);
      if (response["message"]) {
        setSuccessMessage("Updated Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        setLoading(false);
      } else {
        setError("An error occurred");
        setTimeout(() => {
          setError("");
        }, 2000);
        setLoading(false);
      }
    } catch (error) {
      setError("An error Occurred");
    }
  };

  // const handleImageSubmit = async (event: any) => {
  //   event.preventDefault();

  //   if (selectedImage) {
  //     try {
  //       setLoading(true);

  //       setSuccessMessage("Image uploaded successfully.");
  //       setSelectedImage(null);
  //       setLoading(false);
  //     } catch (error) {
  //       setError("Image upload failed. Please try again.");
  //       console.error(error);
  //     }
  //   } else {
  //     setError("Please select an image to upload.");
  //   }
  // };

  // async function handleSelectChange() {
  //   const response = await populateCategories();
  //   if (response.length > 0) {
  //     setCategory(response);
  //   } else {
  //     setCategory([]);
  //   }
  // }

  useEffect(() => {
    getCategory(couponId);
    init();
    // eslint-disable-next-line
  }, [couponId]);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {couponModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bi bi-globe2 small me-2" /> Dashboard
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    <h3
                      id="checkout-form-wizard-h-0"
                      tabIndex={-1}
                      className="title current"
                    >
                      Update Coupons
                    </h3>
                    {successMessage !== "" ? (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    ) : (
                      ""
                    )}
                    {errorMessage !== "" ? (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                    {initialValues.title.length > 0 && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>
                            <div className="row g-4 mb-3">
                              <div className="col-md-12">
                                <div className="my-2">
                                  <label> Title</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter title"
                                    type="text"
                                    name="title"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="title" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="my-2">
                                  <label> Description</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter description"
                                    type="text"
                                    name="description"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="description" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                {" "}
                                <div className="my-2">
                                  <label className="pb-3"> Coupon Code</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter coupon code"
                                    type="text"
                                    name="code"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="code" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3">
                                    {" "}
                                    Discount Amount
                                  </label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter discount amount"
                                    type="text"
                                    name="discount_amount"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="discount_amount" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row g-4 mb-3">
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3"> Current Uses</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter coupon currentuses"
                                    type="number"
                                    name="current_uses"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="current_uses" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3">Max Uses</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter coupon maxuses"
                                    type="text"
                                    name="max_uses"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="max_uses" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row g-4 mb-3">
                              <div className="col-md-12">
                                <div className="my-2">
                                  <label className="pb-3"> Active</label>
                                  <Field
                                    className="form-control"
                                    placeholder=" Select one option"
                                    type="date"
                                    name="active"
                                    as="select"
                                  >
                                    <option value=""> Select the option</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Field>

                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="active" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center my-3">
                              <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary"
                              >
                                Update Coupon
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default UpdateCoupon;
