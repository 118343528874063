import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import AddCategory from "../Modules/Category/AddCategory";
import AddProduct from "../Modules/Product/AddProduct";
import ListProduct from "../Modules/Product/ListProduct";
import ListCoupon from "../Modules/Coupon/ListCoupon";
import AddCoupon from "../Modules/Coupon/AddCoupon";
import UpdateCategory from "../Modules/Category/UpdateCategory";
import UpdateProfile from "../Pages/updateProfile";
import Protected from "./PrivateRoutes";
import UpdateCoupon from "../Modules/Coupon/UpdateCoupon";
import UpdateProduct from "../Modules/Product/UpdateProduct";
import ChangePassword from "../Pages/ChangePassword";
import ListParentCategory from "../Modules/ParentCategory/ListParentCategory";
import AddParentCategory from "../Modules/ParentCategory/AddParentCategory";
import UpdateParentCategory from "../Modules/ParentCategory/UpdateParentCategory";
import ListOrder from "../Modules/Order/ListOrders";
import ListCustomer from "../Modules/Customer/ListCustomer";
import AddNotification from "../Modules/Notification/AddNotification";
import ListNotification from "../Modules/Notification/ListNotification";
import ListCategory from "../Modules/Category/ListCategory";
import OrderDetails from "../Modules/Order/OrderDetails";
import AddDeal from "../Modules/Deal/AddBanners";
import UpdateDeal from "../Modules/Deal/UpdateDeal";
import AddUser from "../Modules/User/AddUser";
import ListUser from "../Modules/User/ListUser";
import DeveloperDashboard from "../Pages/DeveloperDashboard";
import ListCustomerSupport from "../Modules/CustomerSupport/ListCustomerSupport";
import UpdateUser from "../Modules/User/UpdateUser";
import ListVariations from "../Modules/Product/ListVariations";
import AddProductVariation from "../Modules/Product/AddProductVariation";
import ListBanners from "../Modules/Deal/ListBanners";
import UpdateBanner from "../Modules/Deal/UpdateBanner";
import AdminNotfications from "../Pages/AdminNotifications";
import ListProductImages from "../Modules/Product/ListProductImages";
import Payment from "../Pages/Payment";

function AppRoutes() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <Dashboard />
              </Protected>
            }
          />

          <Route
            path="/dashboard/developer"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <DeveloperDashboard />
              </Protected>
            }
          />

          <Route
            path="/"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <Dashboard />
              </Protected>
            }
          />

          <Route
            path="/categories"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListCategory />
              </Protected>
            }
          />

          <Route
            path="/categories/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddCategory />
              </Protected>
            }
          />

          <Route
            path="/categories/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateCategory />
              </Protected>
            }
          />

          {/* PARENT CATEGORIES START */}
          <Route
            path="/parent-categories"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListParentCategory />
              </Protected>
            }
          />

          <Route
            path="/parent-categories/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddParentCategory />
              </Protected>
            }
          />

          <Route
            path="/parent-categories/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateParentCategory />
              </Protected>
            }
          />
          {/* PARENT CATEGORIES END */}
          <Route
            path="/products"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListProduct />
              </Protected>
            }
          />

          <Route
            path="/products/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddProduct />
              </Protected>
            }
          />
          <Route
            path="/products/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateProduct />
              </Protected>
            }
          />

          <Route
            path="/coupon"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListCoupon />
              </Protected>
            }
          />

          <Route
            path="/products/variations/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListVariations />
              </Protected>
            }
          />

          <Route
            path="/products/variations/add/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddProductVariation />
              </Protected>
            }
          />

          <Route
            path="/products/slider-images/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListProductImages />
              </Protected>
            }
          />

          <Route
            path="/coupon/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddCoupon />
              </Protected>
            }
          />

          <Route
            path="/coupon/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateCoupon />
              </Protected>
            }
          />

          <Route
            path="/user/update"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateProfile />
              </Protected>
            }
          />
          <Route
            path="/notification"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListNotification />
              </Protected>
            }
          />
          <Route
            path="/notification/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddNotification />
              </Protected>
            }
          />
          <Route
            path="/updateprofile"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateProfile />
              </Protected>
            }
          />

          <Route
            path="/changepassword"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ChangePassword />
              </Protected>
            }
          />

          <Route
            path="/order"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListOrder />
              </Protected>
            }
          />
          <Route
            path="/order/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <OrderDetails />
              </Protected>
            }
          />

          <Route
            path="/customer"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListCustomer />
              </Protected>
            }
          />

          <Route
            path="/banners"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListBanners />
              </Protected>
            }
          />
          <Route
            path="/banners/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddDeal />
              </Protected>
            }
          />
          <Route
            path="/banners/update/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateBanner />
              </Protected>
            }
          />
          <Route
            path="/banners/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateDeal />
              </Protected>
            }
          />

          <Route
            path="/admin"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListUser />
              </Protected>
            }
          />
          <Route
            path="/admin/add"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AddUser />
              </Protected>
            }
          />
          <Route
            path="/admin/update/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <UpdateUser />
              </Protected>
            }
          />
          <Route
            path="/customer-support"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ListCustomerSupport />
              </Protected>
            }
          />
          <Route
            path="/admin-notifications"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <AdminNotfications />
              </Protected>
            }
          />

          <Route
            path="/payment"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <Payment />
              </Protected>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRoutes;
