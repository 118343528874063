import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthContext } from "../context/authContext";
import axios, { AxiosResponse } from "axios";
import { LoginResponse } from "../type/login";

function Protected({ children }: any) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const authContext = useContext(AuthContext);

  const isLoggedIn = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/auth/is-logged-in`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response: AxiosResponse<LoginResponse>) => {
        const productsAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "products"
        );
        const categoryAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "categories"
        );
        const usersAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "customers"
        );
        const adminAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "admin"
        );
        const ordersAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "orders"
        );
        const couponAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "coupons"
        );
        const taxAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "tax"
        );
        const dealsAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "deals"
        );
        const analyticsAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "analytics"
        );
        const inventoryAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "inventory"
        );
        const invoiceAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "invoice"
        );
        const paymentAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "payments"
        );
        const adminModuleAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "admin"
        );
        const accessModuleAccess = response.data.output.modules.find(
          (m) => m["module_name"] === "modules"
        );
        authContext.setLoggedIn(true);
        authContext.setCategoriesModule(
          categoryAccess && categoryAccess["module_access"] === "1"
            ? true
            : false
        );

        authContext.setUsersModule(
          usersAccess && usersAccess["module_access"] === "1" ? true : false
        );

        authContext.setAdminModules(
          adminAccess && adminAccess["module_access"] === "1" ? true : false
        );

        authContext.setOrdersModule(
          ordersAccess && ordersAccess["module_access"] === "1" ? true : false
        );

        authContext.setCouponModule(
          couponAccess && couponAccess["module_access"] === "1" ? true : false
        );

        authContext.setTaxModule(
          taxAccess && taxAccess["module_access"] === "1" ? true : false
        );

        authContext.setDealsModule(
          dealsAccess && dealsAccess["module_access"] === "1" ? true : false
        );

        authContext.setAnalyticsModule(
          analyticsAccess && analyticsAccess["module_access"] === "1"
            ? true
            : false
        );

        authContext.setInventoryModule(
          inventoryAccess && inventoryAccess["module_access"] === "1"
            ? true
            : false
        );

        authContext.setInvoiceModule(
          invoiceAccess && invoiceAccess["module_access"] === "1" ? true : false
        );

        authContext.setPaymentModule(
          paymentAccess && paymentAccess["module_access"] === "1" ? true : false
        );

        authContext.setAdminModule(
          adminModuleAccess && adminModuleAccess["module_access"] === "1"
            ? true
            : false
        );

        authContext.setAccessModule(
          accessModuleAccess && accessModuleAccess["module_access"] === "1"
            ? true
            : false
        );

        authContext.setProductsModule(
          productsAccess && productsAccess["module_access"] === "1"
            ? true
            : false
        );
        authContext.setCategoriesModule(
          categoryAccess && categoryAccess["module_access"] === "1"
            ? true
            : false
        );
        authContext.setUsersModule(
          usersAccess && usersAccess["module_access"] === "1" ? true : false
        );
        authContext.setAdminModules(
          adminAccess && adminAccess["module_access"] === "1" ? true : false
        );
        authContext.setOrdersModule(
          ordersAccess && ordersAccess["module_access"] === "1" ? true : false
        );
        authContext.setCouponModule(
          couponAccess && couponAccess["module_access"] === "1" ? true : false
        );
        authContext.setTaxModule(
          taxAccess && taxAccess["module_access"] === "1" ? true : false
        );
        authContext.setDealsModule(
          dealsAccess && dealsAccess["module_access"] === "1" ? true : false
        );
        authContext.setAnalyticsModule(
          analyticsAccess && analyticsAccess["module_access"] === "1"
            ? true
            : false
        );
        authContext.setInventoryModule(
          inventoryAccess && inventoryAccess["module_access"] === "1"
            ? true
            : false
        );
        authContext.setInvoiceModule(
          invoiceAccess && invoiceAccess["module_access"] === "1" ? true : false
        );
        authContext.setPaymentModule(
          paymentAccess && paymentAccess["module_access"] === "1" ? true : false
        );
        authContext.setAdminModule(
          adminModuleAccess && adminModuleAccess["module_access"] === "1"
            ? true
            : false
        );
        authContext.setAccessModule(
          accessModuleAccess && accessModuleAccess["module_access"] === "1"
            ? true
            : false
        );
      })
      .catch((error) => {
        authContext.setLoggedIn(false);
        authContext.setCategoriesModule(false);

        authContext.setUsersModule(false);

        authContext.setAdminModules(false);

        authContext.setOrdersModule(false);

        authContext.setCouponModule(false);

        authContext.setTaxModule(false);

        authContext.setDealsModule(false);

        authContext.setAnalyticsModule(false);

        authContext.setInventoryModule(false);

        authContext.setInvoiceModule(false);

        authContext.setPaymentModule(false);

        authContext.setAdminModule(false);

        authContext.setAccessModule(false);

        authContext.setProductsModule(false);

        authContext.setCategoriesModule(false);
        authContext.setUsersModule(false);
        authContext.setAdminModules(false);
        authContext.setOrdersModule(false);
        authContext.setCouponModule(false);
        authContext.setTaxModule(false);
        authContext.setDealsModule(false);
        authContext.setAnalyticsModule(false);
        authContext.setInventoryModule(false);
        authContext.setInvoiceModule(false);
        authContext.setPaymentModule(false);
        authContext.setAdminModule(false);
        authContext.setAccessModule(false);
      });
  };

  isLoggedIn();

  if (!token || authContext.isLoggedIn === false) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

export default Protected;
