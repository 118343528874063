import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  CategoryFormValues,
  // CategoryPopulate,
} from "../../../src/type/category";
import {
  addCategory,
  // populateCategories,
} from "../../Services/categoryService";
import * as Yup from "yup";
import Multiselect from "multiselect-react-dropdown";
import { populateParentCategories } from "../../Services/parentCategortService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const initialValues: CategoryFormValues = {
  name: "",
  parent: 1,
  image: "",
};

const validationSchema = Yup.object({
  // name: Yup.string().required("Category Name is required"),
});

function AddCategory() {
  const [parentCategories, setParentCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { categoriesModule } = useContext(AuthContext);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleSelectChange() {
    const response = await populateParentCategories();
    if (response.length > 0) {
      setParentCategories(response);
    } else {
      setParentCategories([]);
    }
  }

  const handleSubmit = async (
    values: CategoryFormValues,
    formikHelpers: FormikHelpers<CategoryFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");

      const response = await addCategory(values);
      if (response?.message) {
        setSuccessMessage(response?.message);
        setLoading(false);
        setImagePreview(null);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
        window.location.reload();
      } else {
        setError("An error occurred");
      }
    } catch (error) {
      setError("An error occurred while adding the category");
    }
  };

  useEffect(() => {
    handleSelectChange();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {categoriesModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className=" col-md-8">
              <div className="card mb-4">
                <div className="card-body ">
                  <div className="row">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                id="checkout-form-wizard"
                                role="application"
                                className="wizard clearfix"
                              >
                                <div className="content clearfix">
                                  <h3
                                    id="checkout-form-wizard-h-0"
                                    tabIndex={-1}
                                    className="title current"
                                  >
                                    Add Category
                                  </h3>
                                  {successMessage && (
                                    <div
                                      className="alert alert-success"
                                      role="alert"
                                    >
                                      {successMessage}
                                    </div>
                                  )}
                                  {errorMessage && (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">Name</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter category name"
                                          type="text"
                                          name="name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="name" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Parent Category
                                      </label>
                                      <Multiselect
                                        className="form-control"
                                        options={parentCategories}
                                        displayValue="parent_category_name"
                                        selectionLimit={1}
                                        onSearch={handleSelectChange}
                                        onSelect={(selectedList) => {
                                          setFieldValue(
                                            "parent",
                                            selectedList[0]?.parent_category_id
                                          );
                                        }}
                                        onRemove={() => {
                                          setFieldValue("parent", 1);
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="parent" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <label className="pb-3">Upload Photo</label>
                                    <div className="card mb-4">
                                      <div className="card-body">
                                        <div className="mb-3">
                                          <input
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            className="form-control"
                                            onChange={(event) => {
                                              handleImageChange(event);
                                              const file =
                                                event.currentTarget.files?.[0];
                                              if (file) {
                                                setFieldValue("image", file);
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Add Category
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5 border-5">
              {imagePreview && (
                <img
                  className="mt-5 border-5"
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              )}
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AddCategory;
