import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import { Notification } from "../../type/notification";
import getListOfNotification from "../../Services/notificatioService";
import Loader from "../../Fragments/Loader";
import { convertDateToHumanDate } from "../../helpers/functions";
function ListNotification() {
  const [notification, setNotification] = useState<Notification[]>([]);
  const [successMessage, setSuccess] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);

  async function init() {
    setNotification(await getListOfNotification());
    setSuccess("");
    setError("");
    setLoader(false);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="mb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">
                    <i className="bi bi-globe2 small me-2" /> Dashboard
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex gap-4 align-items-center">
                    <div className=" ms-auto">
                      <Link
                        to="/notification/add"
                        className="btn btn-primary "
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Add Notification
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <div
                className="table-responsive"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={1}
              >
                {loader ? (
                  <Loader />
                ) : (
                  <table
                    className="table table-custom table-lg mb-0"
                    id="Notifications"
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Send Date</th>
                        <th>Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notification.length > 0 ? (
                        notification.map((output, index) => (
                          <tr key={index}>
                            <td>{output.id}</td>

                            <td>{output.title}</td>

                            <td>{output.content}</td>

                            <td> {convertDateToHumanDate(output.send_date)}</td>
                            <td>
                              {output.created_by
                                ? output.created_by.admin_first_name +
                                  " " +
                                  output.created_by.admin_last_name
                                : ""}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>No Notification Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListNotification;
