import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import Loader from "../../Fragments/Loader";
import { User } from "../../type/user";
import getListOfUsers, { deleteUser } from "../../Services/userService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";
import { resetAdminPassword } from "../../Services/adminService";

function ListAdmin() {
  const [users, setUsers] = useState<User[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { usersModule } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [showPasswordResetPopup, setShowPasswordResetPopup] = useState(false);
  const [passwordResetUserId, setPasswordResetUserId] = useState(0);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");

  async function handleDelete(e: any, id: number) {
    e.preventDefault();
    setLoader(true);
    const response = await deleteUser(id);
    if (response?.status === 200) {
      setSuccessMessage("User Deleted Successfully");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      init();
    } else {
      setError("An Error Occured While Deleting User");
    }
  }

  async function init() {
    setUsers(await getListOfUsers());
    setLoader(false);
  }

  const showPasswordReset = (e: any, userID: number) => {
    e.preventDefault();
    setPasswordResetUserId(userID);
    setShowPasswordResetPopup(true);
  };

  const hidePasswordReset = () => {
    setShowPasswordResetPopup(false);
  };

  const handleResetPassword = async () => {
    const response = await resetAdminPassword(passwordResetUserId, newPassword);
    if (response.status === 200) {
      setSuccessMessage("Password Reset Successfully");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setShowPasswordResetPopup(false);
    } else {
      setError("An Error Occured While Resetting Password");
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {usersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <i className="bi bi-globe2 small me-2" /> Dashboard
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {users.length} Users
                      </div>

                      <div className=" ms-auto">
                        <Link
                          to="/admin/add"
                          className="btn btn-primary "
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add User
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {successMessage && (
                        <div className="alert alert-success" role="alert">
                          {successMessage}
                        </div>
                      )}
                      {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                          {errorMessage}
                        </div>
                      )}

                      {showPasswordResetPopup && (
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="form-group">
                                <div className="password-reset-popup">
                                  <h2>Reset Password</h2>

                                  <input
                                    type="password"
                                    placeholder="Set New Password"
                                    className="form-control"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn btn-primary my-3"
                                    onClick={handleResetPassword}
                                  >
                                    Reset Password
                                  </button>
                                  <button
                                    className="btn btn-danger mx-4 my-3"
                                    onClick={hidePasswordReset}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {showPasswordResetPopup === false && (
                        <table
                          className="table table-custom table-lg mb-0"
                          id="Categorys"
                        >
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Photo</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Active</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Phone</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.length > 0
                              ? users.map((output, index) => (
                                  <tr key={index}>
                                    <td>{output.admin_id}</td>

                                    <td>
                                      <img
                                        src={
                                          output.admin_photo.length > 0
                                            ? output.admin_photo
                                            : "/assets/images/default.png"
                                        }
                                        alt="Admin"
                                        width={50}
                                        height={50}
                                      />
                                    </td>

                                    <td>{output.admin_first_name}</td>

                                    <td>{output.admin_last_name}</td>
                                    <>
                                      {output.admin_active === 1 ? (
                                        <td style={{ color: "green" }}>Yes</td>
                                      ) : (
                                        <td style={{ color: "red" }}>No</td>
                                      )}
                                    </>
                                    <td>{output.admin_email}</td>
                                    <td>{output.admin_address}</td>
                                    <td>{output.admin_phone}</td>
                                    <td>
                                      <Link
                                        to={`/admin/update/${output.admin_id}`}
                                      >
                                        <button className="btn btn-primary mx-3">
                                          Update
                                        </button>
                                      </Link>

                                      <Link
                                        to={`/admin/delete/${output.admin_id}`}
                                      >
                                        <button
                                          onClick={(e) =>
                                            handleDelete(e, output.admin_id)
                                          }
                                          className="btn btn-danger mx-3"
                                        >
                                          Delete
                                        </button>
                                      </Link>

                                      <Link
                                        to={`/admin/delete/${output.admin_id}`}
                                      >
                                        <button
                                          className="btn btn-primary"
                                          onClick={(e) =>
                                            showPasswordReset(
                                              e,
                                              output.admin_id
                                            )
                                          }
                                        >
                                          Reset Password
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                ))
                              : "No Records Found"}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListAdmin;
