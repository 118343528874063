import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { uploadCategoryImage } from "../../Services/categoryService";
import {
  getSingleParentCategory,
  updateParentCategory,
  uploadParentCategoryImage,
} from "../../Services/parentCategortService";
import { UpdateParentCategoryFormValues } from "../../type/ParentCategory";
import { AuthContext } from "../../context/authContext";

function UpdateParentCategory() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { categoriesModule } = useContext(AuthContext);

  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [initialValues, setInitialValues] =
    useState<UpdateParentCategoryFormValues>({
      name: "",
      active: 0,
    });
  const validationSchema = Yup.object({
    name: Yup.string().required("Category name is required"),
    active: Yup.string().required("Product active status is required"),
  });

  const param = useParams();
  const categoryId = Number(param.id);

  const handleSubmit = async (values: UpdateParentCategoryFormValues) => {
    try {
      setLoading(true);
      const response = await updateParentCategory(categoryId, values);
      if (response["message"]) {
        setSuccessMessage("Updated Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        setLoading(false);
      } else {
        setError("An error occurred");
        setTimeout(() => {
          setError("");
        }, 2000);
        setLoading(false);
      }
    } catch (error) {
      setError("An error Occurred");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        setLoading(true);
        uploadParentCategoryImage(categoryId, selectedImage);

        setSuccessMessage("Image uploaded successfully.");
        setSelectedImage(null);
        setLoading(false);
      } catch (error) {
        setError("Image upload failed. Please try again.");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } else {
      setError("Please select an image to upload.");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  useEffect(() => {
    async function getCategory(categoryId: number) {
      const response = await getSingleParentCategory(categoryId);
      const formattedData: UpdateParentCategoryFormValues = {
        name: response[0].parent_category_name,
        active: response[0].parent_category_active,
      };

      setInitialValues(formattedData);
      setImageUrl(response[0].parent_category_image);
    }
    getCategory(categoryId);
    // eslint-disable-next-line
  }, [categoryId]);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {categoriesModule === null && categoriesModule === true ? (
          <div className="d-flex justify-content-center flex-column align-items-center align-content-center mt-5">
            <img
              src="/assets/images/access-denied.png"
              alt="Access Denied"
              style={{ width: "100px", height: "100px" }}
              className="mt-5"
            />
            <div className="alert alert-danger text-center" role="alert">
              <p>
                You don't have access to this module. Contact Your Administrator
              </p>
            </div>
          </div>
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bi bi-globe2 small me-2" /> Dashboard
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    <h3
                      id="checkout-form-wizard-h-0"
                      tabIndex={-1}
                      className="title current"
                    >
                      Update Parent Category
                    </h3>
                    {successMessage && (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {initialValues.name.length > 0 && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>
                            <div className="row g-4 mb-3">
                              <div className="col-md-12">
                                <div className="my-2">
                                  <label> Name</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter category name"
                                    type="text"
                                    name="name"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="name" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              {" "}
                              <div className="my-2">
                                <label> Active</label>
                                <Field
                                  className="form-control"
                                  placeholder=" Select one option"
                                  name="active"
                                  as="select"
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "active",
                                      Number(e.target.value)
                                    );
                                    setInitialValues((prevValues) => ({
                                      ...prevValues,
                                      active: Number(e.target.value),
                                    }));
                                  }}
                                >
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </Field>

                                <div style={{ color: "red" }}>
                                  <ErrorMessage name="active" />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center my-3">
                              <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary"
                              >
                                Update Category
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    {initialValues.name.length > 0 && (
                      <form onSubmit={handleImageSubmit}>
                        <div className="row g-4 mb-3">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center my-2">
                              {imagePreview ? (
                                <img
                                  className="mt-5 border-5"
                                  src={imagePreview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    imageUrl.length > 0
                                      ? imageUrl
                                      : "/assets/images/default.png"
                                  }
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                  alt={initialValues.name}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="my-2">
                              <input
                                type="file"
                                name="image"
                                accept="image/*"
                                className="form-control"
                                onChange={(event) => {
                                  handleImageChange(event);
                                  handleimageSet(event);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center my-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update Image
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default UpdateParentCategory;
