import Layout from "../Fragments/Layout";
import Header from "../Fragments/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import Footer from "../Fragments/Footer";
import CpuUsage from "../charts/CpuUsage";
import RamUsage from "../charts/RamUsage";
import MemoryUsage from "../charts/MemoryUsage";
import LiveUsers from "../charts/LiveUsers";
function Dashboard() {
  const [totalOrders, settotalOrders] = useState<number>(0);
  const [users, setUsers] = useState<number>(0);
  const [todaySignups, setTodaySignups] = useState<number>(0);
  const [monthSignups, setMonthSignups] = useState<number>(0);
  const [products, setProducts] = useState<number>(0);
  const [coupons, setCoupons] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<string>("0");
  const [monthlyOrders, setMonthlyOrders] = useState<[]>([]);
  const [monthlySalesData, setMonthlySalesData] = useState<[]>([]);

  const [monthlySales, setMonthlySales] = useState<string>("0");

  async function init() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders/count/monthly/orders/single/month`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMonthlyOrders(res.data.output);
      })
      .catch((err) => {});

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders/count/monthly/payments/single/month`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMonthlySalesData(res.data.output);
      })
      .catch((err) => {});
    // PENDING ORDERS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/orders/count/total`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        settotalOrders(res.data.output);
      })
      .catch((err) => {});
    // USERS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/customers/count`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUsers(res.data.output);
      })
      .catch((err) => {});
    // TODAY SIGNUPS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/customers/today`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTodaySignups(Number(res.data.output));
      })
      .catch((err) => {});

    // MONTH SIGNUPS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/customers/this-month`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMonthSignups(res.data.output);
      })
      .catch((err) => {});
    // PRODUCTS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/products/total/count`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setProducts(res.data.output);
      })
      .catch((err) => {});

    // COUPONS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/coupons/active/total/count/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCoupons(res.data.output);
      })
      .catch((err) => {});

    // TOTAL SALES
    await axios
      .get(`${process.env.REACT_APP_API_URL}/orders/count/total-sales`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTotalSales(res.data.output !== null ? res.data.output : "0");
      })
      .catch((err) => {});

    // MONTHLY SALES
    await axios
      .get(`${process.env.REACT_APP_API_URL}/orders/count/monthly-sales`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMonthlySales(res.data.output !== null ? res.data.output : "0");
      })
      .catch((err) => {});
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-3">
              <div className="card widget">
                <div className="card-header d-flex">
                  <h6 className="card-title">Live Users</h6>
                </div>
                <div
                  className="card-body"
                  style={{ position: "relative", backgroundColor: "black" }}
                >
                  <LiveUsers />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card widget">
                <div className="card-header d-flex">
                  <h6 className="card-title">CPU Usage</h6>
                </div>
                <div
                  className="card-body"
                  style={{ position: "relative", backgroundColor: "black" }}
                >
                  <CpuUsage />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card widget">
                <div className="card-header d-flex">
                  <h6 className="card-title">Ram Usage</h6>
                </div>
                <div
                  className="card-body"
                  style={{ position: "relative", backgroundColor: "black" }}
                >
                  <RamUsage />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card widget">
                <div className="card-header d-flex">
                  <h6 className="card-title">Memory Usage</h6>
                </div>
                <div
                  className="card-body"
                  style={{ position: "relative", backgroundColor: "black" }}
                >
                  <MemoryUsage />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title">Sales Chart</h6>
                </div>
                <div className="card-body" style={{ position: "relative" }}>
                  <div className="d-md-flex align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="display-7 me-3">
                        <i className="bi bi-currency-rupee me-2 text-success"></i>{" "}
                        {monthlySalesData !== undefined &&
                        monthlySalesData.length > 0
                          ? monthlySalesData.reduce(
                              (a: any, b: any) => a + b,
                              0
                            )
                          : 0}
                      </div>
                    </div>
                  </div>
                  <LineChart data={monthlySalesData} />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card widget h-100">
                <div className="card-header d-flex">
                  <h6 className="card-title">Orders </h6>
                </div>
                <div className="card-body" style={{ position: "relative" }}>
                  <div className="d-md-flex align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="display-7 me-3">
                        <i className="bi bi-truck me-2 text-success"></i>
                        {monthlyOrders.length > 0
                          ? monthlyOrders.reduce((a: any, b: any) => a + b, 0)
                          : 0}
                      </div>
                    </div>
                  </div>
                  <BarChart data={monthlyOrders} />
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="card widget">
                <div className="card-header">
                  <h5 className="card-title">Dashboard</h5>
                </div>
                <div className="row g-4">
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-person text-secondary" />
                        </div>
                        <h5 className="my-3">Customers</h5>
                        <div className="text-muted">
                          <h3>{users}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-person-add text-warning" />
                        </div>
                        <h5 className="my-3">Today Signup's</h5>
                        <div className="text-muted">
                          <h3>{todaySignups}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-person-circle text-info" />
                        </div>
                        <h5 className="my-3">This Months's Signup</h5>
                        <div className="text-muted">
                          <h3>{monthSignups}</h3>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-truck text-success" />
                        </div>
                        <h5 className="my-3">Total Orders</h5>
                        <div className="text-muted">
                          <h3>{totalOrders}</h3>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 row g-4">
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-truck text-secondary" />
                        </div>
                        <h5 className="my-3">Coupons</h5>
                        <div className="text-muted">
                          {" "}
                          <h3>{coupons}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-receipt text-warning" />
                        </div>
                        <h5 className="my-3">Products</h5>
                        <div className="text-muted">
                          <h3>{products}</h3>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-bar-chart text-info" />
                        </div>
                        <h5 className="my-3">Monthly Sales</h5>
                        <div className="text-muted">
                          <h3>{monthlySales}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-currency-rupee text-success" />
                        </div>
                        <h5 className="my-3">Total Sales</h5>
                        <div className="text-muted">
                          <h3>{totalSales}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 col-md-12">
              <div className="card widget">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title">Recent Orders</h5>
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive"
                    style={{ overflow: "hidden", outline: "none" }}
                    tabIndex={1}
                  >
                    <table
                      className="table table-custom mb-0"
                      id="recent-products"
                    >
                      <thead>
                        <tr>
                          <th>Photo</th>
                          <th>Name</th>
                          <th>Stock</th>
                          <th>Price</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cookie</td>
                          <td>
                            <span className="text-danger">Out of Stock</span>
                          </td>
                          <td>$10,50</td>
                          <td>$10,50</td>
                          <td>$10,50</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
