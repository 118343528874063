import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";
import { convertDateToHumanDate } from "../../helpers/functions";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import { Banner } from "../../type/banner";
import getListOfDeal from "../../Services/bannerService";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function ListBanners() {
  const [count, setCount] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [deals, setDeals] = useState<Banner[]>([]);
  const { dealsModule } = useContext(AuthContext);

  async function init() {
    const deals = await getListOfDeal();
    setDeals(deals);
    setCount(deals.length);
    setLoader(true);
    setLoader(false);
  }

  async function handleDelete(id: number) {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    await axios
      .delete(`${process.env.REACT_APP_API_URL}/banners/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setSuccessMessage(response.data.message);
        init();
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {dealsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Banners</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {count} Banners
                      </div>

                      <div className=" ms-auto">
                        <Link
                          to="/banners/add"
                          className="btn btn-primary "
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add Banners
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <table
                      className="table table-custom table-lg mb-0 mx-2"
                      id="Categorys"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Image</th>
                          <th>Created By</th>
                          <th>Created At</th>
                          <th>Updated By</th>
                          <th>Updated At</th>
                          <th>Update</th>
                          <th>Delete</th>
                        </tr>
                      </thead>

                      <tbody>
                        {deals.length > 0
                          ? deals.map((output, index) => (
                              <tr key={index}>
                                <td>{output.id}</td>
                                <td>
                                  <img
                                    src={
                                      output.url.length
                                        ? output.url
                                        : "/assets/images/default.png"
                                    }
                                    alt="category"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </td>

                                <td>
                                  {output.createBy.admin_first_name +
                                    " " +
                                    output.createBy.admin_last_name}
                                </td>
                                <td>
                                  {convertDateToHumanDate(output.createdAt)}
                                </td>
                                <td>
                                  {output.updatedBy.admin_first_name +
                                    " " +
                                    output.updatedBy.admin_last_name}
                                </td>
                                <td>
                                  {convertDateToHumanDate(output.updatedAt)}
                                </td>
                                <td className="text-end">
                                  <div className="d-flex">
                                    <Link to={`/banners/update/${output.id}`}>
                                      <i className="bi bi-pencil small me-2 customIcon mx-3"></i>
                                    </Link>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div className="d-flex">
                                    <i
                                      onClick={() => handleDelete(output.id)}
                                      className="bi bi-trash small me-2 customIcon mx-3"
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : "No Records Found"}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListBanners;
