import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { addNotification } from "../../Services/notificatioService";
import { NotificationFormValues } from "../../type/notification";

const initialValues: NotificationFormValues = {
  title: "",
  content: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("Notification Titlte is required"),
  content: Yup.string().required(" Message is required"),
});

function AddNotification() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    values: NotificationFormValues,
    formikHelpers: FormikHelpers<NotificationFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");

      const response = await addNotification(values);
      if (response?.message) {
        setSuccessMessage(response?.message);
        setLoading(false);

        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
      } else {
        setError("An error occurred");
      }
    } catch (error) {
      setError("An error occurred while adding the category");
    }
  };

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content row">
          <div className=" col-md-8">
            <div className="card mb-4">
              <div className="card-body ">
                <div className="row">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              id="checkout-form-wizard"
                              role="application"
                              className="wizard clearfix"
                            >
                              <div className="content clearfix">
                                <h3
                                  id="checkout-form-wizard-h-0"
                                  tabIndex={-1}
                                  className="title current"
                                >
                                  Add Notification
                                </h3>
                                {successMessage && (
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    {successMessage}
                                  </div>
                                )}
                                {errorMessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {errorMessage}
                                  </div>
                                )}

                                <div className="row g-4 mb-3">
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">Title</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter Title"
                                        type="text"
                                        name="title"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="title" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-4">
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">Message</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter Message"
                                        type="text"
                                        name="content"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="content" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center my-3">
                                  <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Add Notification
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddNotification;
