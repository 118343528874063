import { Link } from "react-router-dom";
import AdminNotfications from "../Pages/AdminNotifications";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";

function Footer() {
  const authContext = useContext(AuthContext);
  return (
    <>
      {authContext.notificationSidebar && <AdminNotfications />}
      <footer className="content-footer">
        <div>
          © {Date().split(" ")[3]} Developed by
          <Link to="https://wallayl.com" className="" target="_blank">
            &nbsp;Wal Layl
          </Link>
        </div>
      </footer>
    </>
  );
}

export default Footer;
