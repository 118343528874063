import { TotalCount } from "../type/customer";
import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfCustomer(
  pageNumber: number,
  pageSize: number
) {
  const response = await axios.get(
    `${API_URL}/customers/${pageNumber}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
}

export async function getCountOfCustomer(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/customers/count`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
}

export async function deactivateAccount(userId: number) {
  const response: AxiosResponse = await axios.get(
    `${API_URL}/customers/user/account/deactivate/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response;
}

export async function activateAccount(userId: number) {
  const response: AxiosResponse = await axios.get(
    `${API_URL}/customers/user/account/activate/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response;
}

export async function resetPassword(userId: number, password: string) {
  const response: AxiosResponse = await axios.post(
    `${API_URL}/customers/reset-password/${userId}`,
    { password },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response;
}

export async function serachCustomers(query: string) {
  const response: AxiosResponse = await axios.get(
    `${API_URL}/customers/list/search-customer/${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}
