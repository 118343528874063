import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { useState } from "react";

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import * as Yup from "yup";
import { AddBannerFormValues } from "../../type/banner";
import { CategoryPopulate } from "../../type/category";
import { populateCategories } from "../../Services/categoryService";
import getListOfProducts from "../../Services/productService";
import { Product } from "../../type/product";

const initialValues: AddBannerFormValues = {
  bannerType: "",
  bannerLocation: "",
  categoryId: 0,
  productId: 0,
};

const validationSchema = Yup.object().shape({
  categoryId: Yup.number().min(0, "Category must be a positive number"),
  productId: Yup.number().min(0, "Product must be a positive number"),
});

function AddBanner() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [featuredImage, setFeaturedImage] = useState<any>("");
  const [category, setCategory] = useState<CategoryPopulate[]>([]);
  const [product, setProduct] = useState<Product[]>([]);

  async function handleSelectChange() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  async function handleSelectProductChange() {
    const response = await getListOfProducts(1, 10000);
    if (response.output.data.length > 0) {
      setProduct(response.output.data);
    } else {
      setProduct([]);
    }
  }

  async function handleSubmit(values: AddBannerFormValues) {
    setLoading(true);

    const formData = new FormData();
    formData.append("bannerType", values.bannerType);
    formData.append("bannerLocation", values.bannerLocation);

    if (values.bannerType === "category") {
      formData.append("categoryId", values.categoryId.toString());
      formData.append("productId", "null");
    } else if (values.bannerType === "product") {
      formData.append("categoryId", "null");
      formData.append("productId", values.productId.toString());
    } else {
      // Promotional banner type
      formData.append("categoryId", "null");
      formData.append("productId", "null");
    }

    formData.append("image", featuredImage);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/banners`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setSuccessMessage(response.data.message);
      setLoading(false);

      setTimeout(() => {
        setSuccessMessage("");
        window.location.reload();
      }, 1000);
    } catch (error) {
      setLoading(false);
      setError("Only jpg and png files are allowed with max size 1mb");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />

        <div className="content row">
          <div className=" col-md-10">
            <div className="card mb-4">
              <div className="card-body ">
                <div className="row">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              id="checkout-form-wizard"
                              role="application"
                              className="wizard clearfix"
                            >
                              <div className="content clearfix">
                                <h3
                                  id="checkout-form-wizard-h-0"
                                  tabIndex={-1}
                                  className="title current"
                                >
                                  Add Banners
                                </h3>
                                {successMessage && (
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    {successMessage}
                                  </div>
                                )}
                                {errorMessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {errorMessage}
                                  </div>
                                )}

                                <div className="row g-4 mb-3">
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Featured Image
                                      </label>
                                      <Field
                                        className="form-control"
                                        type="file"
                                        name="image"
                                        onChange={(event: any) => {
                                          const file = event.target.files[0];
                                          setFeaturedImage(file);
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="image" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Banner Type
                                      </label>
                                      <Field
                                        as="select"
                                        className="form-control"
                                        defaultValue=""
                                        name="bannerType"
                                      >
                                        <option value="" disabled>
                                          Select Banner Type
                                        </option>
                                        <option value="promotional">
                                          Promotional
                                        </option>
                                        <option value="category">
                                          Category
                                        </option>
                                        <option value="product">Product</option>
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        Banner Location
                                      </label>
                                      <Field
                                        as="select"
                                        className="form-control"
                                        defaultValue=""
                                        name="bannerLocation"
                                      >
                                        <option value="" disabled>
                                          Select Banner Location
                                        </option>
                                        <option value="home_page_top">
                                          Top
                                        </option>
                                        <option value="home_page_bottom">
                                          Bottom
                                        </option>
                                        <option value="home_page_middle">
                                          Middle
                                        </option>
                                      </Field>
                                    </div>
                                  </div>
                                </div>

                                {values.bannerType === "category" && (
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3"> Category</label>
                                      <Multiselect
                                        className="form-control"
                                        options={category}
                                        displayValue="category_name"
                                        selectionLimit={1}
                                        onSearch={handleSelectChange}
                                        onSelect={(selectedList) => {
                                          const selectedCategories =
                                            selectedList.map(
                                              (item: any) => item.category_id
                                            );
                                          setFieldValue(
                                            "categoryId",
                                            selectedCategories[0]
                                          );
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="categoryId" />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {values.bannerType === "product" && (
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">Product</label>
                                      <Multiselect
                                        className="form-control"
                                        options={product}
                                        displayValue="product_name"
                                        selectionLimit={1}
                                        onSearch={handleSelectProductChange}
                                        onSelect={(selectedList) => {
                                          const selectedProducts =
                                            selectedList.map(
                                              (item: any) => item.product_id
                                            );
                                          setFieldValue(
                                            "productId",
                                            selectedProducts[0]
                                          );
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="productId" />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="d-flex justify-content-center my-3">
                                  <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Add Banner
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default AddBanner;
