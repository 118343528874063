import Layout from "../Fragments/Layout";
import Header from "../Fragments/Header";
import { useEffect, useState } from "react";
import axios from "axios";

function DeveloperDashboard() {
  const [totalApiCalls, setTotalApiCalls] = useState(0);
  const [totalDataUsage, setTotalDataUsage] = useState(0);
  const [totalErrorCalls, setTotalErrorCalls] = useState(0);
  const [averageResponseTime, setAverageResponseTime] = useState(0);

  async function init() {
    // PENDING ORDERS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/statistics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTotalApiCalls(res.data.output.totalApiCalls);
        setTotalDataUsage(res.data.output.totalDataUsage);
        setTotalErrorCalls(res.data.output.errorCalls);
        setAverageResponseTime(res.data.output.responseTime);
      });
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        <div className="content ">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col-lg-12 col-md-12">
              <div className="card widget">
                <div className="card-header">
                  <h5 className="card-title">Developer Dashboard</h5>
                </div>
                <div className="row g-4">
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-truck text-secondary" />
                        </div>
                        <h5 className="my-3">API Calls</h5>
                        <div className="text-muted">
                          <h3>{totalApiCalls}</h3>
                        </div>
                        <div
                          className="progress mt-3"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar bg-secondary"
                            role="progressbar"
                            style={{ width: "25%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-receipt text-warning" />
                        </div>
                        <h5 className="my-3">Data Usage</h5>
                        <div className="text-muted">
                          <h3>{totalDataUsage.toFixed(3)} GB</h3>
                        </div>
                        <div
                          className="progress mt-3"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: "67%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-bar-chart text-info" />
                        </div>
                        <h5 className="my-3">Error Calls</h5>
                        <div className="text-muted">
                          <h3>{totalErrorCalls}</h3>{" "}
                        </div>
                        <div
                          className="progress mt-3"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "80%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <div className="display-5">
                          <i className="bi bi-cursor text-success" />
                        </div>
                        <h5 className="my-3">Average Response Time</h5>
                        <div className="text-muted">
                          <h3>{averageResponseTime.toFixed(3)} ms</h3>{" "}
                        </div>
                        <div
                          className="progress mt-3"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "55%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeveloperDashboard;
