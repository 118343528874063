import GaugeChart from "react-gauge-chart";

function LiveUsers() {
  return (
    <GaugeChart
      id="gauge-chart5"
      nrOfLevels={1000}
      arcsLength={[1]}
      colors={["#5BE12C", "#F5CD19", "#EA4228"]}
      percent={Math.random()}
      arcPadding={0.02}
    />
  );
}

export default LiveUsers;
