import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";
import { Category } from "../../../src/type/category";

import {
  deleteCategory,
  getCategoresinPagination,
  getCategoriesByOptions,
  getCountOfCategory,
  getQueryCategories,
} from "../../Services/categoryService";

import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import {
  convertDateToHumanDate,
  convertFirstLetterToUpperCase,
} from "../../helpers/functions";
import Loader from "../../Fragments/Loader";
import CategoryPagination from "./CategoryPagination";
import SearchCategory from "./SearchCategory";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function ListCategory() {
  const [category, setCategory] = useState<Category[]>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedLimit, setSelectedLimit] = useState<string>("25");
  const [selectedOption, setSelectedOption] = useState<string>("dsc");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const { categoriesModule } = useContext(AuthContext);

  async function init() {
    const count = await getCountOfCategory();
    setCount(count.output);
    setError("");
    setLoader(false);
  }
  async function handleDelete(id: number) {
    const response = await deleteCategory(id);
    if (response?.status === 200) {
      setSuccessMessage(response.data.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
      init();
      setSelectedOption("a");
    }
    if (response?.status === 400) {
      setError(response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  }

  async function handleOptions(selectedValue: string) {
    setSelectedOption(selectedValue);
    const categories = await getCategoriesByOptions(selectedValue);
    setCategory(categories);
  }

  async function getCategoriesFromAPInPagination(page: number, limit: number) {
    const categories = await getCategoresinPagination(page, limit);
    setCategory(categories);
  }

  async function handleSearch(e: any) {
    if (e.target.value === "") {
      return init();
    }
    const data = await getQueryCategories(e.target.value);
    if (data.length > 0) {
      setCategory(await getQueryCategories(e.target.value));
    }
  }

  async function handleLimit(e: string) {
    setSelectedLimit(e);
  }

  const [page, setPage] = useState(1);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const totalPages = Math.ceil(count / Number(selectedLimit));

  const MAX_PAGE_DISPLAY = Number(selectedLimit);

  const renderPageLinks = () => {
    const startPage = Math.max(1, page - Math.floor(MAX_PAGE_DISPLAY / 2));
    const endPage = Math.min(totalPages, startPage + MAX_PAGE_DISPLAY - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  useEffect(() => {
    init();
    handleLimit(selectedLimit);
    getCategoriesFromAPInPagination(page, MAX_PAGE_DISPLAY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLimit, page]);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {categoriesModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Categories</li>
                </ol>
              </nav>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {count} Categories
                      </div>
                      <div className="d-md-flex gap-4 align-items-center">
                        <form className="mb-3 mb-md-0">
                          <div className="row">
                            <div className="col-md-6">
                              <select
                                onChange={(e) => handleOptions(e.target.value)}
                                name="kt_ecommerce_products_table_length"
                                aria-controls="kt_ecommerce_products_table"
                                className="form-select"
                                defaultValue={selectedOption}
                              >
                                <option>Sort by</option>
                                <option value="desc">Desc</option>
                                <option value="asc">Asc</option>
                                <option value="active">Active</option>
                                <option value="inactive">In Active</option>
                                <option value="latest">Latest</option>
                              </select>
                            </div>

                            <div className="col-md-6">
                              <div className="col-md-12">
                                <select
                                  onChange={(e) => handleLimit(e.target.value)}
                                  name="kt_ecommerce_products_table_length"
                                  aria-controls="kt_ecommerce_products_table"
                                  className="form-select"
                                  defaultValue={selectedLimit}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <SearchCategory handleSearch={handleSearch} />

                      <div className=" ms-auto">
                        <Link
                          to="/categories/add"
                          className="btn btn-primary "
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add Category
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <nav
                        className="mt-4"
                        aria-label="Page navigation example"
                      >
                        <ul className="pagination justify-content-center">
                          <li
                            className={`page-item ${
                              page === 1 ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="btn btn-sm btn-primary mx-2"
                              onClick={handlePrevPage}
                              disabled={page === 1}
                            >
                              Previous Page
                            </button>
                          </li>
                          {renderPageLinks().map((pageNum) => (
                            <li
                              className={`page-item ${
                                pageNum === page ? "active" : ""
                              }`}
                              key={pageNum}
                            >
                              <button
                                className="page-link"
                                onClick={() => setPage(pageNum)}
                              >
                                {pageNum}
                              </button>
                            </li>
                          ))}
                          <li
                            className={`page-item ${
                              page === totalPages ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="btn btn-sm btn-primary mx-2"
                              onClick={handleNextPage}
                              disabled={page === totalPages}
                            >
                              Next Page
                            </button>
                          </li>
                        </ul>
                      </nav>
                      <table
                        className="table table-custom table-lg mb-0 mx-2"
                        id="Categorys"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Active</th>
                            <th>Parent Category</th>
                            <th>Created At</th>
                            <th>Created By</th>
                            <th>Updated At</th>
                            <th>Updated By</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {category && category.length > 0 ? (
                            category.map((output, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/category/add/${output.category_id}`}
                                  >
                                    {output.category_id}
                                  </Link>
                                </td>
                                <td>
                                  <img
                                    src={
                                      output.category_image
                                        ? output.category_image
                                        : "/assets/images/default.png"
                                    }
                                    alt="category"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </td>

                                <td>{output.category_name}</td>

                                <td
                                  style={{
                                    color:
                                      output.category_active === 1
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {output.category_active === 1 ? "Yes" : "No"}
                                </td>

                                <td>{output.parent_category_name}</td>

                                <td>
                                  {convertDateToHumanDate(
                                    output.category_created_date
                                  )}
                                </td>
                                <td>
                                  {convertFirstLetterToUpperCase(
                                    output.category_created_by
                                  )}
                                </td>
                                <td>
                                  {convertDateToHumanDate(
                                    output.category_updated_date
                                  )}
                                </td>

                                <td>
                                  {convertFirstLetterToUpperCase(
                                    output.category_updated_by
                                  )}
                                </td>

                                <td className="text-end">
                                  <div className="d-flex">
                                    <Link
                                      to={`/categories/${output.category_id}`}
                                      target="_blank"
                                    >
                                      <i className="bi bi-pencil small me-2 customIcon mx-3"></i>
                                    </Link>

                                    <i
                                      onClick={() =>
                                        handleDelete(output.category_id)
                                      }
                                      className="bi bi-trash small me-2 customIcon mx-3"
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <h5 className="d-flex justify-content-center text-center">
                              No Records Found
                            </h5>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <CategoryPagination
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListCategory;
