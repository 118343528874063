import Cookies from "universal-cookie";

function Payment() {
  async function handleLogout() {
    new Cookies().remove("token");
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
  async function handleDashboard() {
    window.location.href = "/dashboard";
  }
  return (
    <>
      <div className="container text-center p-5 p-md-0 my-5">
        <div className="row mb-4">
          <div className="col-md-4 m-auto">
            <figure>
              <img
                className="img-fluid"
                src="assets/images/1000.png"
                alt="Payment Pending"
                style={{ width: "70%", height: "auto" }}
              />
            </figure>
          </div>
        </div>
        <h2 className="display-6">Payment Pending</h2>
        <p className="text-muted my-4">Please Pay Your Maintenance Charges</p>
        <div className="d-flex gap-3 justify-content-center">
          <button onClick={handleLogout} className="btn btn-primary">
            Log Out
          </button>
          <button onClick={handleDashboard} className="btn bg-white">
            Skip (3)
          </button>
        </div>
      </div>
    </>
  );
}

export default Payment;
