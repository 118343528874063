import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { AdminUserModulesIF, UpdateUserFormValues } from "../../type/user";
import { updateUser } from "../../Services/userService";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const validationSchema = Yup.object({
  admin_first_name: Yup.string().required("First name is required"),
  admin_middle_name: Yup.string().optional(),
  admin_last_name: Yup.string().required("Last name is required"),
  admin_email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  admin_phone: Yup.string()
    .required("Phone number is required")
    .max(10)
    .min(10),
  admin_address: Yup.string().required("Address is required"),
  admin_city: Yup.string().required("City is required"),
  admin_state: Yup.string().required("State is required"),
  admin_zip: Yup.string().required("Zip code is required"),
  admin_country: Yup.string().required("Country is required"),
  admin_username: Yup.string().required("Username is required"),
  modules: Yup.array(),
});

function UpdateUser() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [successImageMessage, setSuccessImageMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { usersModule } = useContext(AuthContext);
  const [modules, setModules] = useState<AdminUserModulesIF[]>([]);

  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const param = useParams<{ id: string }>();

  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        const formData = new FormData();
        formData.append("image", selectedImage);
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/admin/pic/${param.id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setSuccessImageMessage("Image uploaded successfully.");
            setSelectedImage(null);
          })
          .catch((error) => {});
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [initialValues, setInitialValues] = useState<UpdateUserFormValues>({
    admin_first_name: "",
    admin_middle_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_address: "",
    admin_city: "",
    admin_state: "",
    admin_zip: "",
    admin_country: "",
    admin_username: "",
    modules: [],
  });

  const handleSubmit = async (
    values: UpdateUserFormValues,
    formikHelpers: FormikHelpers<UpdateUserFormValues>
  ) => {
    try {
      setLoading(true);
      values.modules = modules.map((module) => module);
      const response = await updateUser(String(param.id), values);
      if (response?.status === 200) {
        setSuccessMessage(response.data.message);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
      } else {
        setError(response?.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
        window.location.reload();
      }
    } catch (error) {
      setError("An error ");
      setTimeout(() => {
        setError("");
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  async function init() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/all/${param.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInitialValues({
          admin_first_name: response.data.output.admin.admin_first_name,
          admin_middle_name: response.data.output.admin.admin_middle_name,
          admin_last_name: response.data.output.admin.admin_last_name,
          admin_email: response.data.output.admin.admin_email,
          admin_phone: response.data.output.admin.admin_phone,
          admin_address: response.data.output.admin.admin_address,
          admin_city: response.data.output.admin.admin_city,
          admin_state: response.data.output.admin.admin_state,
          admin_zip: response.data.output.admin.admin_zip,
          admin_country: response.data.output.admin.admin_country,
          admin_username: response.data.output.admin.admin_username,
          modules: [],
        });
        setImageUrl(response.data.output.admin.admin_photo);
        setModules(response.data.output.modules);
      })
      .catch((error) => {});
  }

  const handleModuleChange = (moduleId: number, checked: boolean) => {
    const newModules = modules.map((module) =>
      module.module_id === moduleId
        ? { ...module, module_access: checked ? "1" : "0", hasAccess: checked }
        : module
    );

    setModules(newModules);
  };

  let availableModulesWithAccess = modules.map((module) => {
    if (module.module_access === "1") {
      return { ...module, hasAccess: true };
    }
    return { ...module, hasAccess: false };
  });

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {usersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className=" col-md-12">
              <div className="card mb-4">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-center text-center text-md-start mb-3">
                              <figure className="me-4 flex-shrink-0">
                                {imagePreview ? (
                                  <img
                                    width="100"
                                    className="rounded-pill"
                                    src={imagePreview}
                                    alt="Preview"
                                  />
                                ) : (
                                  <img
                                    width="100"
                                    className="rounded-pill"
                                    src={
                                      imageUrl.length > 0
                                        ? imageUrl
                                        : "/assets/images/default.png"
                                    }
                                    alt="Not Avaialble"
                                  />
                                )}
                              </figure>
                              <div className="flex-fill">
                                <h5 className="mb-3">
                                  {`${initialValues?.admin_first_name}  ${initialValues?.admin_last_name}`}
                                </h5>
                              </div>
                            </div>
                            {successImageMessage && (
                              <div className="alert alert-success">
                                {successImageMessage}
                              </div>
                            )}
                            {errorMessage && (
                              <div className="alert alert-danger">
                                {errorMessage}
                              </div>
                            )}
                            <div className="row">
                              <form onSubmit={handleImageSubmit}>
                                <div className="row g-4 mb-3">
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={(event) => {
                                          handleImageChange(event);
                                          handleimageSet(event);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center my-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Update Image
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        id="checkout-form-wizard"
                        role="application"
                        className="wizard clearfix"
                      >
                        <div className="content clearfix">
                          <h3
                            id="checkout-form-wizard-h-0"
                            tabIndex={-1}
                            className="title current"
                          >
                            Update User
                          </h3>
                          {successMessage !== "" ? (
                            <div className="alert alert-success" role="alert">
                              {successMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          {errorMessage !== "" ? (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          {initialValues.admin_email.length > 0 ? (
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={handleSubmit}
                            >
                              {({ values, setFieldValue }) => (
                                <Form>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          First Name
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter first name"
                                          type="text"
                                          name="admin_first_name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_first_name" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Middle Name
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter middle name"
                                          type="text"
                                          name="admin_middle_name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_middle_name" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Last Name
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter last name"
                                          type="text"
                                          name="admin_last_name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_last_name" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          User Name
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter user name"
                                          type="text"
                                          name="admin_username"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_username" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">Email</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter email"
                                          type="email"
                                          name="admin_email"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_email" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Phone Number
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter phone number"
                                          type="text"
                                          name="admin_phone"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_phone" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3"> Adress</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter adress"
                                          type="text"
                                          name="admin_address"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_address" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">Pincode</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter pincode"
                                          type="text"
                                          name="admin_zip"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_zip" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3"> State</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Select state"
                                          type="date"
                                          name="admin_state"
                                          as="select"
                                        >
                                          <option value="">State</option>
                                          <option value="victoria">
                                            Victoria
                                          </option>
                                          <option value="alaska">Alaska</option>
                                          <option value="california">
                                            California
                                          </option>
                                          <option value="texas">Texas</option>
                                          <option value="queensland">
                                            Queensland
                                          </option>
                                        </Field>

                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_state" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">City</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Select city"
                                          type="date"
                                          name="admin_city"
                                          as="select"
                                        >
                                          <option value="">City</option>
                                          <option value="victoria">
                                            Victoria
                                          </option>
                                          <option value="alaska">Alaska</option>
                                          <option value="california">
                                            California
                                          </option>
                                          <option value="texas">Texas</option>
                                          <option value="queensland">
                                            Queensland
                                          </option>
                                        </Field>

                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_city" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3"> Country</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Select country"
                                          type="date"
                                          name="admin_country"
                                          as="select"
                                        >
                                          <option value="">Country</option>

                                          <option value="victoria">
                                            Victoria
                                          </option>
                                          <option value="alaska">Alaska</option>
                                          <option value="california">
                                            California
                                          </option>
                                          <option value="texas">Texas</option>
                                          <option value="queensland">
                                            Queensland
                                          </option>
                                        </Field>
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="admin_country" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="content clearfix">
                                    <h3
                                      id="checkout-form-wizard-h-0"
                                      tabIndex={-1}
                                      className="title current"
                                    >
                                      Modules
                                    </h3>

                                    <div className="row g-4 mb-3">
                                      {availableModulesWithAccess.map(
                                        (module, index) => (
                                          <div className="col-md-3" key={index}>
                                            <div className="my-3">
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  name="modules"
                                                  id={`module-checkbox-${module.module_id}`}
                                                  value={module.module_id}
                                                  checked={
                                                    module.module_access === "1"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    const checked =
                                                      e.target.checked;
                                                    handleModuleChange(
                                                      module.module_id,
                                                      checked
                                                    );

                                                    // const newModulesState =
                                                    //   checked
                                                    //     ? [
                                                    //         ...values.modules,
                                                    //         module.module_id,
                                                    //       ]
                                                    //     : values.modules.filter(
                                                    //         (m) => m !== module
                                                    //       );
                                                    // setFieldValue(
                                                    //   "modules",
                                                    //   newModulesState
                                                    // );
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`module-checkbox-${module.module_id}`}
                                                >
                                                  {module.module_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    module.module_name.slice(1)}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>

                                    <div className="d-flex justify-content-center my-3">
                                      <button
                                        disabled={loading}
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update User
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default UpdateUser;
