export function convertDateToHumanDate(value: string): string {
  const date = new Date(value);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-IN", options);
  return formattedDate;
}

export function convertFirstLetterToUpperCase(value: string) {
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
}

export function timeAgo(isoDateString: string): string {
  const date = new Date(isoDateString);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(diff / 3600000);
  const days = Math.floor(diff / 86400000);
  const months = Math.floor(diff / 2592000000); // assuming 30 days in a month
  const years = Math.floor(diff / 31536000000); // assuming 365 days in a year

  if (years > 0) {
    return `${years} ${years === 1 ? "year" : "years"} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 1) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return "Just now";
  }
}
