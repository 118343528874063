import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getAdminDetails() {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const response = await axios.get(`${API_URL}/admin/profile`, {
    headers,
  });
  return response.data;
}

export async function resetAdminPassword(userId: number, password: string) {
  const response = await axios.patch(
    `${API_URL}/admin/reset-admin-password/${userId}`,
    { admin_password: password },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response;
}
