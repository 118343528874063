import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";

import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import getListOfCustomer, {
  activateAccount,
  deactivateAccount,
  resetPassword,
  serachCustomers,
} from "../../Services/customerService";

import { Customer } from "../../type/customer";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function ListCustomer() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [customer, setCustomer] = useState<Customer[]>([]);
  const { usersModule } = useContext(AuthContext);
  const [count, setCount] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [showPasswordResetPopup, setShowPasswordResetPopup] = useState(false);
  const [passwordResetUserId, setPasswordResetUserId] = useState(0);

  const showPasswordReset = (e: any, userID: number) => {
    e.preventDefault();
    setPasswordResetUserId(userID);
    setShowPasswordResetPopup(true);
  };

  const hidePasswordReset = () => {
    setShowPasswordResetPopup(false);
  };

  const handleResetPassword = async () => {
    const response = await resetPassword(passwordResetUserId, newPassword);
    if (response.status === 200) {
      setSuccessMessage("Password Reset Successfully");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setShowPasswordResetPopup(false);
    }
  };

  const [page, setPage] = useState(1);
  const limit = 25;

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const totalPages = Math.ceil(count / limit);

  async function init() {
    setSuccessMessage("");
    setError("");
    setLoader(true);
    const response = await getListOfCustomer(page, limit);
    if (response.output.count > 0) {
      setCustomer(response.output.customers);
      setCount(response.output.count);
      setLoader(false);
    } else {
    }
  }

  const MAX_PAGE_DISPLAY = 10;

  const renderPageLinks = () => {
    const startPage = Math.max(1, page - Math.floor(MAX_PAGE_DISPLAY / 2));
    const endPage = Math.min(totalPages, startPage + MAX_PAGE_DISPLAY - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  async function handleActive(e: any, id: number) {
    e.preventDefault();
    const response = await activateAccount(id);
    if (response.status === 200) {
      init();
    }
  }

  async function handleInactive(e: any, id: number) {
    e.preventDefault();
    const response = await deactivateAccount(id);
    if (response.status === 200) {
      init();
    }
  }

  async function handleSearch(e: any) {
    if (e.target.value === "") {
      return init();
    }
    const output = await serachCustomers(e.target.value);
    if (output.length > 0) {
      setCustomer(output);
    } else {
      init();
    }
  }

  useEffect(() => {
    init();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {usersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Customers</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div
                  className="table-responsive"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="card">
                        {showPasswordResetPopup === false && (
                          <div className="card-body">
                            <div className="d-none d-md-flex">
                              Total {count} Customers
                            </div>
                            <div className="row my-3">
                              <div className="col-md-5">
                                <input
                                  className="form-control"
                                  placeholder="Search for a customer..."
                                  onChange={(e) => handleSearch(e)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {showPasswordResetPopup === false && (
                        <nav
                          className="mt-4"
                          aria-label="Page navigation example"
                        >
                          <ul className="pagination justify-content-center">
                            <li
                              className={`page-item ${
                                page === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="btn btn-sm btn-primary mx-2"
                                onClick={handlePrevPage}
                                disabled={page === 1}
                              >
                                Previous Page
                              </button>
                            </li>
                            {renderPageLinks().map((pageNum) => (
                              <li
                                className={`page-item ${
                                  pageNum === page ? "active" : ""
                                }`}
                                key={pageNum}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setPage(pageNum)}
                                >
                                  {pageNum}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${
                                page === totalPages ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="btn btn-sm btn-primary mx-2"
                                onClick={handleNextPage}
                                disabled={page === totalPages}
                              >
                                Next Page
                              </button>
                            </li>
                          </ul>
                        </nav>
                      )}
                      {showPasswordResetPopup && (
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="form-group">
                                <div className="password-reset-popup">
                                  <h2>Reset Password</h2>

                                  <input
                                    type="password"
                                    placeholder="Set New Password"
                                    className="form-control"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn btn-primary my-3"
                                    onClick={handleResetPassword}
                                  >
                                    Reset Password
                                  </button>
                                  <button
                                    className="btn btn-danger mx-4 my-3"
                                    onClick={hidePasswordReset}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <table
                        className="table table-custom table-lg mb-0 mx-2"
                        id="Categorys"
                      >
                        {showPasswordResetPopup === false && (
                          <thead>
                            <tr>
                              <th>Photo</th>
                              <th>Name</th>
                              <th>Mobile Number</th>
                              <th>Email</th>
                              <th>Email Verified</th>
                              <th>Active</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {showPasswordResetPopup === false &&
                          customer.length > 0
                            ? customer.map((output, index) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={
                                        output.user_photo
                                          ? output.user_photo
                                          : "/assets/images/default.png"
                                      }
                                      alt="cat"
                                      width={50}
                                      height={50}
                                    />
                                  </td>
                                  <td>
                                    {output.user_first_name
                                      ? output.user_first_name
                                      : "Not Updated Yet"}
                                  </td>
                                  <td>{output.user_phone_number}</td>
                                  <td>
                                    {output.user_email
                                      ? output.user_email
                                      : "Not Updated Yet"}
                                  </td>
                                  <td>
                                    {output.user_email_verified === 1
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td>
                                    {output.user_active === 1 ? "Yes" : "No"}
                                  </td>
                                  <td className="text-end">
                                    <div className="d-flex">
                                      {output.user_active === 1 ? (
                                        <button
                                          onClick={(e) =>
                                            handleInactive(e, output.user_id)
                                          }
                                          className="btn btn-danger btn-icon btn-sm"
                                        >
                                          Deactivate
                                        </button>
                                      ) : (
                                        <button
                                          onClick={(e) =>
                                            handleActive(e, output.user_id)
                                          }
                                          className="btn btn-primary btn-icon btn-sm"
                                        >
                                          Activate
                                        </button>
                                      )}

                                      <button
                                        className="btn btn-primary btn-icon btn-sm mx-3"
                                        onClick={(e) =>
                                          showPasswordReset(e, output.user_id)
                                        }
                                      >
                                        Reset Password
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : showPasswordResetPopup === false && (
                                <tr>
                                  <td colSpan={8} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListCustomer;
