import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { useParams } from "react-router-dom";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import Cookies from "universal-cookie";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

interface Images {
  id: number;
  image_name: string;
}

function ListProductImages() {
  const { productsModule } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState<Array<Images>>([]);
  const [image, setImage] = useState<File | null>(null);
  const [imageId, setImageId] = useState<number | null>(null);
  const [images, setImages] = useState<Array<File>>([]);

  const param = useParams<{ id: string }>();

  async function init() {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/products/slider/images/all/${param.id}`,
        {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.output);
        setLoader(false);
      });
  }

  async function handleDelete(e: any, id: number) {
    e.preventDefault();
    await axios
      .delete(process.env.REACT_APP_API_URL + `/products/slider/images/${id}`, {
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      })
      .then((response) => {
        setLoader(true);
        init();
        setLoader(false);
      });
  }

  async function handleUpdate() {
    setLoader(true);
    const formData = new FormData();
    formData.append("image", image!);
    await axios
      .put(
        process.env.REACT_APP_API_URL + `/products/slider/images/${imageId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      )
      .then((response) => {
        setSuccessMessage(response.data.message);
        setLoader(true);
        init();
        setLoader(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoader(false);
      });
  }

  async function handleUpload(id: number) {
    setLoader(true);
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });
    await axios
      .post(
        process.env.REACT_APP_API_URL + `/products/upload-images/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      )
      .then((response) => {
        setSuccessMessage(response.data.message);
        setLoader(true);
        init();
        setLoader(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoader(false);
      });
  }

  useEffect(() => {
    if (image) {
      handleUpdate();
    }
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Product Images</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex gap-4 align-items-center">
                      <div className="d-none d-md-flex">
                        Total {data.length} Images
                      </div>

                      <div className="">
                        <form className="d-flex align-items-center justify-content-center">
                          <input
                            className="form-control mx-3"
                            type="file"
                            onChange={(e) => {
                              setImages(Array.from(e.target.files!));
                            }}
                            multiple
                          />
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleUpload(Number(param.id));
                            }}
                          >
                            Upload New Image
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row my-5 mx-3"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={1}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {data.length > 0 ? (
                        data.map((item) => (
                          <div key={item.id} className="col-md-4 px-5 my-3">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={
                                  item.image_name.length > 0
                                    ? item.image_name
                                    : "/assets/images/default.png"
                                }
                                className="img-fluid"
                                alt="Slider"
                                style={{ width: "200px", height: "200px" }}
                              />
                              <input
                                type="file"
                                required
                                className="form-control mt-2"
                                onChange={(e) => {
                                  setImage(e.target.files![0]);
                                  setImageId(item.id);
                                }}
                              />

                              <button
                                className="btn btn-danger mt-2 "
                                onClick={(e) => handleDelete(e, item.id)}
                              >
                                Delete Image
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-md-12">
                          <div className="alert alert-danger" role="alert">
                            No Images Found
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListProductImages;
