import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { CouponFormValues } from "../../type/coupon";
import { addCoupon } from "../../Services/couponService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const initialValues: CouponFormValues = {
  title: "",
  description: "",
  code: "",
  discount_amount: 0,
  max_uses: 0,
  start_date: "",
  end_date: "",
};

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(2, "Title must be at least 2 characters")
    .max(255, "Title cannot exceed 255 characters"),
  description: Yup.string()
    .min(10, "Description must be at least 10 characters")
    .max(500, "Description cannot exceed 500 characters"),
  code: Yup.string()
    .required("Code is required")
    .matches(
      /^[A-Z0-9]+$/,
      "Code must contain only uppercase letters and numbers"
    ),
  discount_amount: Yup.number()
    .required("Discount amount is required")
    .positive("Discount amount must be a positive number")
    .max(100, "Discount amount cannot exceed 100%"),
  max_uses: Yup.number()
    .required("Maximum uses is required")
    .integer("Maximum uses must be an integer")
    .min(0, "Maximum uses cannot be negative"),
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date()
    .required("End date is required")
    .min(
      Yup.ref("start_date"),
      "End date must be greater than or equal to start date"
    ),
});

function AddCoupon() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { couponModule } = useContext(AuthContext);

  const handleSubmit = async (
    values: CouponFormValues,
    formikHelpers: FormikHelpers<CouponFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");
      const coupon: CouponFormValues = {
        title: values.title,
        description: values.description,
        code: values.code,
        discount_amount: values.discount_amount,
        max_uses: values.max_uses,
        start_date: values.start_date,
        end_date: values.end_date,
      };
      const response = await addCoupon(coupon);
      if (response.message) {
        setSuccessMessage(response.message);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
        setLoading(false);
      } else {
        setError("An error Occurred");
        setLoading(false);
      }
    } catch (error) {
      setError("An error Occurred");
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {couponModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className=" col-md-11">
              <div className="card mb-4">
                <div className="card-body ">
                  <div style={{ display: "grid", placeItems: "center" }}>
                    <div className="row">
                      <div className="col-md-11">
                        <div
                          id="checkout-form-wizard"
                          role="application"
                          className="wizard clearfix"
                        >
                          <div className="content clearfix">
                            <h3
                              id="checkout-form-wizard-h-0"
                              tabIndex={-1}
                              className="title current"
                            >
                              Add Coupon
                            </h3>
                            {successMessage !== "" ? (
                              <div className="alert alert-success" role="alert">
                                {successMessage}
                              </div>
                            ) : (
                              ""
                            )}
                            {errorMessage !== "" ? (
                              <div className="alert alert-danger" role="alert">
                                {errorMessage}
                              </div>
                            ) : (
                              ""
                            )}
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={handleSubmit}
                            >
                              {({ values, setFieldValue }) => (
                                <Form>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3"> Title</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter title"
                                          type="text"
                                          name="title"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="title" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          {" "}
                                          Description
                                        </label>
                                        <textarea
                                          className="form-control"
                                          placeholder="Enter description"
                                          name="description"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="description" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      {" "}
                                      <div className="my-2">
                                        <label className="pb-3">
                                          {" "}
                                          Coupon Code
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter coupon code"
                                          type="text"
                                          name="code"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="code" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          {" "}
                                          Discount Amount
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter discount amount"
                                          type="number"
                                          name="discount_amount"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="discount_amount" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">Max Uses</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter maxuses"
                                          type="number"
                                          name="max_uses"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="max_uses" />
                                        </div>
                                      </div>
                                    </div>
                                    {/* start date */}
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Start Date
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter start date"
                                          type="date"
                                          name="start_date"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="start_date" />
                                        </div>
                                      </div>
                                    </div>

                                    {/* end date */}
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">End Date</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter end date"
                                          type="date"
                                          name="end_date"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="end_date" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Add Coupon
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AddCoupon;
