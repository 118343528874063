import axios from "axios";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import Cookies from "universal-cookie";

function RamUsage() {
  const [ramUsage, setRamUsage] = useState(0);

  useEffect(() => {
    const fetchRamUsage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/statistics/usage/ram`,
          {
            headers: {
              Authorization: `Bearer ${new Cookies().get("token")}`,
            },
          }
        );
        setRamUsage(parseFloat(response.data.output.replace("%", "")) / 100);
      } catch (err) {
        console.error("Error fetching Memory usage:", err);
        setRamUsage(0);
      }
    };

    fetchRamUsage();
  }, []);
  return (
    <GaugeChart
      id="gauge-chart6"
      animate={false}
      nrOfLevels={15}
      percent={ramUsage}
      needleColor="#345243"
    />
  );
}

export default RamUsage;
