import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

function UpdateDeal() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { dealsModule } = useContext(AuthContext);

  const param = useParams<{ id: string }>();

  async function init() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/banners/${param.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setImageUrl(response.data.output.deal_banner_image);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        const formData = new FormData();
        formData.append("image", selectedImage);
        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/banners/${param.id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setImageUrl("");
            setLoading(true);
            setSuccessMessage("Image uploaded successfully.");
            setSelectedImage(null);
            setLoading(false);
            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);
          })
          .catch((error) => {
            setError(error.response.data.message);
          });
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  useEffect(() => {
    init();
  });

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {dealsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className="col-md-8">
              <div
                id="checkout-form-wizard"
                role="application"
                className="wizard clearfix"
              >
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="content clearfix">
                  <form onSubmit={handleImageSubmit}>
                    <div className="row g-4 mb-3">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-center my-2">
                          {imagePreview ? (
                            <img
                              className="mt-5 border-5"
                              src={imagePreview}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "300px",
                              }}
                            />
                          ) : (
                            <img
                              src={
                                imageUrl.length > 0
                                  ? imageUrl
                                  : "/assets/images/default.png"
                              }
                              style={{
                                maxWidth: "100%",
                                maxHeight: "300px",
                              }}
                              alt="Preview"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="my-2">
                          <input
                            type="file"
                            name="image"
                            accept="image/*"
                            className="form-control"
                            onChange={(event) => {
                              handleImageChange(event);
                              handleimageSet(event);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                      <button
                        disabled={loading}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Update Banner
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default UpdateDeal;
