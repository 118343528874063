import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import Cookies from "universal-cookie";
import { timeAgo } from "../helpers/functions";

function AdminNotfications() {
  const [notifications, setNotifications] = useState<any>([]);

  async function getNotifications() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/notifications/all/admin`, {
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      })
      .then((res) => {
        setNotifications(res.data.output.notifications);
      });
  }

  async function markAllAsRead() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/notifications/mark/all/admin`, {
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      })
      .then((res) => {
        getNotifications();
      });
    getNotifications();
    window.location.reload();
  }

  useEffect(() => {
    getNotifications();
  }, []);

  const authContext = useContext(AuthContext);

  function handleClose() {
    authContext.setNotificationSidebar(false);
  }

  return (
    <>
      <div className="sidebar show" id="notifications" style={{ width: "60%" }}>
        <div className="sidebar-header d-block align-items-end">
          <div className="align-items-center d-flex justify-content-between py-4">
            Notifications
            <button data-sidebar-close="">
              <i className="bi bi-arrow-right" />
            </button>
          </div>
        </div>
        <div className="sidebar-content" tabIndex={4}>
          <div className="tab-content">
            <div className="tab-pane active" id="activities">
              <div className="tab-pane-body">
                <ul className="list-group list-group-flush">
                  {notifications.map((notification: any) => (
                    <li
                      className={`px-0 list-group-item ${
                        notification.is_read ? "" : "bg-light"
                      }`}
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <figure className="avatar avatar-info me-3">
                            <span className="avatar-text rounded-circle">
                              <i className={`bi bi-${notification.icon}`} />
                            </span>
                          </figure>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-0 fw-bold d-flex justify-content-between">
                            {notification.title}
                          </p>
                          <span className="text-muted small">
                            <i className={`bi bi-${notification.icon} me-1`} />
                            {timeAgo(notification.created_date)}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="tab-pane-footer">
                <div className="btn btn-success" onClick={markAllAsRead}>
                  <i className="bi bi-check2 me-2" /> Mark all as read
                </div>
                <div onClick={handleClose} className="btn btn-danger ms-2">
                  <i className="bi bi- me-2" /> Close Notifications
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ascrail2003"
          className="nicescroll-rails nicescroll-rails-vr"
          style={{
            width: 8,
            zIndex: 1000,
            cursor: "default",
            position: "absolute",
            top: "151.3px",
            left: 412,
            height: "543.9px",
            display: "none",
            opacity: 0,
          }}
        >
          <div
            className="nicescroll-cursors"
            style={{
              position: "relative",
              top: 0,
              float: "right",
              width: 6,
              height: 0,
              backgroundColor: "rgb(66, 66, 66)",
              border: "1px solid rgb(255, 255, 255)",
              backgroundClip: "padding-box",
              borderRadius: 5,
            }}
          />
        </div>
        <div
          id="ascrail2003-hr"
          className="nicescroll-rails nicescroll-rails-hr"
          style={{
            height: 8,
            zIndex: 1000,
            top: "687.2px",
            left: 0,
            position: "absolute",
            cursor: "default",
            display: "none",
            opacity: 0,
          }}
        >
          <div
            className="nicescroll-cursors"
            style={{
              position: "absolute",
              top: 0,
              height: 6,
              width: 0,
              backgroundColor: "rgb(66, 66, 66)",
              border: "1px solid rgb(255, 255, 255)",
              backgroundClip: "padding-box",
              borderRadius: 5,
              left: 0,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default AdminNotfications;
