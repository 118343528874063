import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";

import { AuthContext } from "../../context/authContext";
import { ProductVariationFormValues } from "../../type/product";
import { addVariation } from "../../Services/productService";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const initialValues: ProductVariationFormValues = {
  name: "",
  description: "",
  price: 0,
  max_price: 0,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  price: Yup.number().required("Required"),
  max_price: Yup.number().required("Required"),
});

function AddProductVariation() {
  const { productsModule } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams<{ id: string }>();

  const handleSubmit = async (
    values: ProductVariationFormValues,
    formikHelpers: FormikHelpers<ProductVariationFormValues>
  ) => {
    try {
      const response = await addVariation(
        Number(params.id),
        values.name,
        values.price,
        values.max_price
      );
      if (response?.status === 200) {
        setSuccessMessage("Product variation added successfully!");
        setLoading(false);
        setError("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        formikHelpers.resetForm();
      } else {
        setLoading(false);
        setError("An error occurred while adding the product");
      }
    } catch (error) {
      setError("An error occurred while adding the product");
    }
  };

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row ">
            <div className=" col-md-8">
              <div className="card mb-4">
                <div className="card-body ">
                  <div className="row">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                id="checkout-form-wizard"
                                role="application"
                                className="wizard clearfix"
                              >
                                <div className="content clearfix">
                                  <h3
                                    id="checkout-form-wizard-h-0"
                                    tabIndex={-1}
                                    className="title current"
                                  >
                                    Add Products
                                  </h3>
                                  {successMessage && (
                                    <div
                                      className="alert alert-success"
                                      role="alert"
                                    >
                                      {successMessage}
                                    </div>
                                  )}
                                  {errorMessage && (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Variation Name
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter variation name"
                                          type="text"
                                          name="name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="name" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">Price</label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter Price "
                                          type="number"
                                          name="price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="price" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Max Price
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter Price "
                                          type="number"
                                          name="max_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="max_price" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Description
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter Description"
                                          type="text"
                                          name="description"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="description" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Add Variation
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AddProductVariation;
