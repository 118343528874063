import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

interface AuthContextType {
  logout: () => void;
  setToken?: (token: string) => void;

  isLoggedIn: boolean | null;
  setLoggedIn: (isLoggedIn: boolean | null) => void;

  notificationSidebar: boolean;
  setNotificationSidebar: (notificationSidebar: boolean) => void;

  notificationCount: number;
  setNotificationCount: (notificationCount: number) => void;

  productsModule: boolean | undefined;
  setProductsModule: (productsModule: boolean) => void;

  categoriesModule: boolean | undefined;
  setCategoriesModule: (categoriesModule: boolean) => void;

  usersModule: boolean | undefined;
  setUsersModule: (usersModule: boolean) => void;

  adminModules: boolean | undefined;
  setAdminModules: (adminModules: boolean) => void;

  ordersModule: boolean | undefined;
  setOrdersModule: (ordersModule: boolean) => void;

  couponModule: boolean | undefined;
  setCouponModule: (couponModule: boolean) => void;

  taxModule: boolean | undefined;
  setTaxModule: (taxModule: boolean) => void;

  dealsModule: boolean | undefined;
  setDealsModule: (dealsModule: boolean) => void;

  analyticsModule: boolean | undefined;
  setAnalyticsModule: (analyticsModule: boolean) => void;

  inventoryModule: boolean | undefined;
  setInventoryModule: (inventoryModule: boolean) => void;

  invoiceModule: boolean | undefined;
  setInvoiceModule: (invoiceModule: boolean) => void;

  paymentModule: boolean | undefined;
  setPaymentModule: (paymentModule: boolean) => void;

  adminModule: boolean | undefined;
  setAdminModule: (adminModule: boolean) => void;

  accessModule: boolean | undefined;
  setAccessModule: (accessModule: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  setToken: () => {},
  isLoggedIn: false,
  setLoggedIn: () => {},

  notificationSidebar: false,
  setNotificationSidebar: () => {},

  notificationCount: 0,
  setNotificationCount: () => {},

  productsModule: undefined,
  setProductsModule: () => {},

  categoriesModule: undefined,
  setCategoriesModule: () => {},

  usersModule: undefined,
  setUsersModule: () => {},

  adminModules: undefined,
  setAdminModules: () => {},

  ordersModule: undefined,
  setOrdersModule: () => {},

  couponModule: undefined,
  setCouponModule: () => {},

  taxModule: undefined,
  setTaxModule: () => {},

  dealsModule: undefined,
  setDealsModule: () => {},

  analyticsModule: undefined,
  setAnalyticsModule: () => {},

  inventoryModule: undefined,
  setInventoryModule: () => {},

  invoiceModule: undefined,
  setInvoiceModule: () => {},

  paymentModule: undefined,
  setPaymentModule: () => {},

  adminModule: undefined,
  setAdminModule: () => {},

  accessModule: undefined,
  setAccessModule: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [notificationSidebar, setNotificationSidebar] =
    useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [token, setToken] = useState<string | undefined>("");
  // ACCESS MODULES
  const [productsModule, setProductsModule] = useState<boolean>(false);
  const [categoriesModule, setCategoriesModule] = useState<boolean | undefined>(
    false
  );
  const [usersModule, setUsersModule] = useState<boolean | undefined>(false);
  const [adminModules, setAdminModules] = useState<boolean | undefined>(false);
  const [ordersModule, setOrdersModule] = useState<boolean | undefined>(false);
  const [couponModule, setCouponModule] = useState<boolean | undefined>(false);
  const [taxModule, setTaxModule] = useState<boolean | undefined>(false);
  const [dealsModule, setDealsModule] = useState<boolean | undefined>(false);
  const [analyticsModule, setAnalyticsModule] = useState<boolean | undefined>(
    false
  );
  const [inventoryModule, setInventoryModule] = useState<boolean | undefined>(
    false
  );
  const [invoiceModule, setInvoiceModule] = useState<boolean | undefined>(
    false
  );
  const [paymentModule, setPaymentModule] = useState<boolean | undefined>(
    false
  );
  const [adminModule, setAdminModule] = useState<boolean | undefined>(false);
  const [accessModule, setAccessModule] = useState<boolean | undefined>(false);

  useEffect(() => {}, [isLoggedIn]);

  const logout = () => {
    setLoggedIn(false);
    setToken("");
    setProductsModule(false);
    setCategoriesModule(false);
    setUsersModule(false);
    setAdminModules(false);
    setOrdersModule(false);
    setCouponModule(false);
    setTaxModule(false);
    setDealsModule(false);
    setAnalyticsModule(false);
    setInventoryModule(false);
    setInvoiceModule(false);
    setPaymentModule(false);
    setAdminModule(false);
    setAccessModule(false);

    localStorage.removeItem("token");
    const cookies = new Cookies();
    cookies.remove("token");
  };

  const authContextValue = {
    isLoggedIn,
    setLoggedIn,
    logout,
    token,
    setToken,

    notificationSidebar,
    setNotificationSidebar,

    notificationCount,
    setNotificationCount,

    productsModule,
    setProductsModule,

    categoriesModule,
    setCategoriesModule,

    usersModule,
    setUsersModule,

    adminModules,
    setAdminModules,

    ordersModule,
    setOrdersModule,

    couponModule,
    setCouponModule,

    taxModule,
    setTaxModule,

    dealsModule,
    setDealsModule,

    analyticsModule,
    setAnalyticsModule,

    inventoryModule,
    setInventoryModule,

    invoiceModule,
    setInvoiceModule,

    paymentModule,
    setPaymentModule,

    adminModule,
    setAdminModule,

    accessModule,
    setAccessModule,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
